import { isDefined } from "./evaluate";
import { compareStrings, isString } from "./string";

export const userTypes = {
  OWNER: "OWNR",
  INSURED: "INSD",
  PREMIUM_PAYER: "PPAY",
  BENEFICARY: "BENE",
};

export const agentTypes = {
  WRITING_AGENT: "WRTG",
  SERVICING_AGENT: "SVC",
};

export const roleNames = {
  OWNR: "Owner",
  INSD: "Insured",
  PPAY: "Premium Payor",
  BENE: "Beneficiary",
  WRTG: "Writing Agent",
  SVC: "Servicing Agent",
  ASGN: "Assignee",
  POA: "Power of Attorney",
  RCP: "Alternate Addressee",
  RCPBEN: "Recipient of Benefits",
  PAY: "Payee",
};

export const supportedTransactions = {
  ChangeAddress: "ChangeAddress",
  InitiateNewLoan: "InitiateNewLoan",
  RequestInformation: "RequestInformation",
  ChangeBillingFrequency: "ChangeBillingFrequency",
  InForceIllustration: "InForceIllustration",
  FundTransfer: "FundTransfer",
  InitiateDeathClaim: "InitiateDeathClaim",
  DirectLinks: "DirectLinks",
  CashValueQuote: "CashValueQuote",
  PolicyCoverageSummary: "PolicyCoverageSummary",
  DigitalRegistration: "DigitalRegistration",
  FillableFormLinks: "FillableFormLinks",
};

export const getRoleName = (name, subName) => {
  if (!isString(name)) return "";
  let roleName = roleNames[name];
  if (compareStrings(name, "RCP") && compareStrings(subName, "PAY")) {
    roleName = roleNames.PAY;
  }

  return isDefined(roleName) ? roleName : "";
};

export const supportedAnnuityRiderNames = {
  TERMINAL_ILLNESS: "Terminal Illness Benefit Rider",
  NURSING_HOSPITAL: "Nursing Home and Hospital Waiver",
  NURSING_HOME_BENEFIT: "Nursing Home Benefit Rider",
  NURSING_HOME_HEALTH_CARE: "Nursing Home Waiver and Home Health Care Benefit",
  STANDARD_DEATH: "Standard Death Benefit",
  BASIC_DEATH: "Basic Death Benefit",
  BASIC_DEATH_ROLLUP: "Basic Death Benefit with 5% Rollup",
  BASIC_DEATH_COMBO: "Basic Death Benefit with Combination",
  BASIC_DEATH_3YR_RESET: "Basic Death Benefit with 3 Year Reset",
  BASIC_DEATH_ANNUAL_RATCHET: "Basic Death Benefit with Annual Ratchet",
  BASIC_DEATH_CONTRACT_VALUE: "Contract Value Death Benefit",
  EQUALIZER_BENEFIT: "Equalizer Benefit",
  GMAB_10:
    "10 Year Guaranteed Minimum Accumulation Benefit Period with Reset Option",
  GMAB_20: "20 Year Guaranteed Minimum Accumulation Benefit Period",
  E_DOCUMENTS: "e-Documents<sup>SM</sup>",
  EARNINGS_ENHANCEMENT: "Earnings Enhancement Benefit",
  RATCHET_DB: "Annual Ratchet Death Benefit",
};

export const supportedAnnuityRiderTypes = {
  GMWB: "GMWB",
  GMIB: "GMIB",
  PPB: "PPB",
  LIP: "LIP",
  EEB: "EEB",
};

export const annuityDisplayTitles = {
  TERMINAL_ILLNESS: "Terminal Illness Benefit Rider",
  NURSING_HOSPITAL_WAIVER: "Nursing Home & Hospital Waiver",
  NURSING_HOME_HOSPITAL_WAIVER: "Nursing Home and Hospital Waiver",
  NURSING_HOME_BENEFIT_RIDER: "Nursing Home Benefit Rider",
  NURSING_HOME_WAIVER_AND_HOME_HEALTH_CARE_BENEFIT:
    "Nursing Home Waiver and Home Health Care Benefit",
  GMAB: "Guaranteed Minimum Accumulation Benefit",
  LIFETIME_INCOME_PROTECTOR: "Lifetime Income Protector",
  BASIC_DEATH_BENEFIT: "Basic Death Benefit",
  GMWB: "Guaranteed Minimum Withdrawal Benefit",
  GMIB: "Guaranteed Minimum Income Benefit",
  PRINCIPAL_PROTECTION_BENEFIT: "Principal Protection Benefit",
  STANDARD_DEATH: "Standard Death Benefit",
  BASIC_DEATH: "Basic Death Benefit",
  EARNINGS_ENHANCEMENT_BENEFIT: "Earnings Enhancement Benefit",
  E_DOCUMENTS: "e-Documents SM",
  EQUALIZER_BENEFIT: "Equalizer Benefit",
  DISABILITY_WAIVER_OF_PREMIUM: "Disability Waiver of Premium",
  RATCHET_DEATH_BENEFIT: "Ratchet Death Benefit",
  ANNUAL_RATCHET_DEATH_BENEFIT: "Annual Ratchet Death Benefit",
};

export const contactInformationUpdateAction = { EDIT: "EDIT", ADD: "ADD" };

export const deepLinkType = {
  ADDRESS_CHANGE: "addr",
  BENEFICIARY_CHANGE: "bene",
  CASH_VALUE_QUOTE: "cvq",
  LOAN_REQUEST: "loan",
  NAME_CHANGE: "name",
  OWNER_CHANGE: "ownc",
  PAY_BILL: "pay",
  POLICY_COVERAGE_SUMMARY: "pcs",
  TRANSACTION_STATUS: "trx",
  DOCHUB: "1099",
  DISABILITY_CLAIM: "dis",
  PAPERLESS_PREFERENCES: "paper",
};

export const deathBenefitOptionNameWithEeb = {
  "Basic Death Benefit":
    "Basic Death Benefit with Earnings Enhancement Benefit",
  "Contract Value Death Benefit":
    "Contract Value Death Benefit with Earnings Enhancement Benefit",
  "Basic Death Benefit with 3 Year Reset":
    "Basic Death Benefit with 3 Year Reset & Earnings Enhancement Benefit",
  "Basic Death Benefit with 5% Rollup":
    "Basic Death Benefit with 5% Rollup & Earnings Enhancement Benefit",
  "Basic Death Benefit with Annual Ratchet":
    "Basic Death Benefit with Annual Ratchet & Earnings Enhancement Benefit",
  "Basic Death Benefit with Combination":
    "Basic Death Benefit with Combination & Earnings Enhancement Benefit",
};

export const customerSelectedDeliveryPreferences = {
  MAIL: "US Mail",
  EMAIL: "Paperless",
};

export const docusignSigningEvents = {
  ACCESS_CODE_FAILED: {
    code: "access_code_failed",
    description: "Recipient used incorrect access code.",
  },
  CANCEL: {
    code: "cancel",
    description:
      "Recipient canceled the signing operation, possibly by using the Finish Later option.",
  },
  DECLINE: {
    code: "decline",
    description: "Recipient declined to sign.",
  },
  EXCEPTION: {
    code: "exception",
    description: "A system error occurred during the signing process.",
  },
  FAX_PENDING: {
    code: "fax_pending",
    description: "Recipient has a fax pending.",
  },
  ID_CHECK_FAILED: {
    code: "id_check_failed",
    description: "Recipient failed an ID check.",
  },
  SESSION_TIMEOUT: {
    code: "session_timeout",
    description:
      "The session timed out. An account can control this timeout by using the Signer Session Timeout option.",
  },
  SIGNING_COMPLETE: {
    code: "signing_complete",
    description: "The recipient completed the signing ceremony.",
  },
  TTL_EXPIRED: {
    code: "ttl_expired",
    description:
      "The Time To Live token for the envelope has expired. After being successfully invoked, these tokens expire after five minutes.",
  },
  VIEWING_COMPLETE: {
    code: "viewing_complete",
    description:
      "The recipient completed viewing an envelope that is in a read-only/terminal state, such as completed, declined, or voided.",
  },
};
