import React from "react";
import { useParams } from "react-router-dom";
import IsTruthy from "../logic/isTruthy";
import { isValidDPTxnId } from "../../../utils/evaluate";
import IsFalsy from "../logic/isFalsy";
import { copyClipboardData } from "../../../utils/copyClipboardData";
import { GetErrorReferenceValue } from "./hooks";

const FiveHundredError = (props) => {
  const {
    turnOffReload,
    retryRequest,
    transactionId,
    title = "The page you are looking for is temporarily unavailable.",
    agreementKey = "",
    showBackToPolicyButton = true,
  } = props;
  const { agreementKey: agreementKeyFromUrl } = useParams();
  const errorReferenceValue = transactionId || GetErrorReferenceValue();
  return (
    <>
      <div className="container">
        <IsTruthy value={showBackToPolicyButton}>
          <button id="go_back_btn" type="button" className="btn btn-link">
            <span className="icon-arrow-circle-spin-left" />
            {"  "}{" "}
            <a
              id="go_back_link"
              href={`/policyDetails/${encodeURIComponent(
                agreementKey || agreementKeyFromUrl
              )}`}
            >
              Back to Policy Details
            </a>
          </button>
        </IsTruthy>
      </div>
      <div className="row">
        <div className="col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3">
          <picture className="justify-content-center mm-spacing">
            <span
              role="img"
              className="illustration-error_500"
              title="Error Site Down"
            />
          </picture>
          <p className="h1 mm-text-center">{title}</p>
          <IsFalsy value={turnOffReload}>
            <div className="justify-content-center">
              <button
                type="button"
                className="btn btn-link "
                onClick={retryRequest}
              >
                <span className="icon-reload" /> Reload the Page
              </button>
            </div>
          </IsFalsy>
          <div className="justify-content-center">
            <IsTruthy
              value={errorReferenceValue}
              validationFunction={isValidDPTxnId}
            >
              <div className="justify-content-center">
                <span>Error Reference </span>
                <span>&nbsp;</span>
                <span id="transactionId">{errorReferenceValue}</span>
                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                <button
                  id="copy_icon_btn"
                  className="btn btn-inline"
                  type="button"
                  onClick={() => copyClipboardData(errorReferenceValue)}
                >
                  <span
                    className="icon-copy icon-sm"
                    aria-hidden="true"
                    title="Copy Transaction Id"
                  />
                  <strong>Copy</strong>
                </button>
              </div>
            </IsTruthy>
          </div>
        </div>
      </div>
    </>
  );
};

export default FiveHundredError;
