import { getUsers } from "../../utils/findData";
import {
  checkIfTransactionIsAllowed,
  isPolicyInForce,
} from "../../utils/policies";
import { supportedTransactions } from "../../utils/translate";

export const verifyFillableFormLibraryLink = (props) => {
  const { agreementData, transactionSupportedPolicies } = props;

  const { isTransactionAllowed } = checkIfTransactionIsAllowed({
    transactionSupportedPolicies,
    transactionName: supportedTransactions.FillableFormLinks,
    agreementData,
  });

  const returnObject = {
    isEligible: isAgreementDataEligibleForFormLibraryLink(agreementData),
    isTransactionAllowed,
  };

  return returnObject;
};

export const isAgreementDataEligibleForFormLibraryLink = (agreementData) => {
  const { status } = agreementData;
  const isValidPolicyStatus = isPolicyInForce(status);

  const { agreementCustomers } = agreementData;

  const owners = getUsers(agreementCustomers, "OWNR") || [];

  const isSingleIndividualOwned =
    owners.length === 1 &&
    owners[0].type === "I" &&
    owners[0].roleSubType === "LIST";

  return isValidPolicyStatus && isSingleIndividualOwned;
};
