import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { useMakeRequest } from "../../hooks/api";
import { isEnvisionPolicy } from "../../utils/policies";
import { useLDValue } from "../common/featureFlags/hooks";
import { compareStrings } from "../../utils/string";
import { AGREEMENT_NOT_FOUND } from "../../constants/ui";
import { verifyFundTransfer } from "./restrictions";
import { isDefined } from "../../utils/evaluate";
import { getBpIdFromUser, getUsers } from "../../utils/findData";
import { typesOfAgents } from "../../data/typesOfAgents";
import { useUserState } from "../../contexts/user";
import { getFundTransferType } from "../../utils/transactions";
import { userTypes } from "../../utils/translate";

export const useFundTransferProps = () => {
  const { agreementKey } = useParams();
  const [pageNumber, setPageNumber] = useState(1);
  const [fundAllocationEligibilityError, setFundAllocationEligibilityError] =
    useState("");
  const nextPage = () => setPageNumber(pageNumber + 1);
  const lastPage = () => setPageNumber(pageNumber - 1);
  const previousPage = () => {
    lastPage();
  };

  const { user } = useUserState();
  const { agentType } = getBpIdFromUser(user);
  const isMmsdOnlyUser = compareStrings(agentType, typesOfAgents.MMSD);

  const transactionSupportedPolicies = useLDValue({
    flagName: ["transactionSupportedPolicies"],
  });

  const validRequest = (dependentAgreementData) => {
    const { isUnauthorized } = dependentAgreementData;
    const { type, description } = dependentAgreementData?.messages || {};
    return (
      !isUnauthorized &&
      compareStrings(type, "SUCCESS") &&
      !compareStrings(description, AGREEMENT_NOT_FOUND)
    );
  };

  const producerRequest = useMakeRequest({
    apiRequest: "fetchProducersData",
    apiParams: { agreementKey },
    immediateRequest: false,
    dataSelector: "processProducersData",
    canExecuteCallBack: validRequest,
  });

  const agreementRequest = useMakeRequest({
    apiRequest: "fetchAgreementData",
    apiParams: { agreementKey },
    immediateRequest: true,
    dataSelector: "processAgreementRequest",
    dependentRequests: [producerRequest],
  });
  const agreementData = agreementRequest?.data || {};
  const { agreementCustomers } = agreementData;
  const owner = getUsers(agreementCustomers, userTypes.OWNER)[0] || {};
  const { memberGUID } = owner;

  const fundTransferEligibilityRequest = useMakeRequest({
    apiRequest: "fetchFundTransferEligibility",
    apiParams: {
      memberGUID,
      agreementKey,
      fundTransferType: getFundTransferType(agreementData),
    },
    immediateRequest: false,
    dataSelector: "processFundTransferEligibility",
  });

  const { isUnauthorized, policyId, lineOfBusinessCode, isPolicyNotFound } =
    agreementData;
  const isPolicyLoaded = isDefined(policyId) && isDefined(lineOfBusinessCode);

  const showEnvisionPage = isEnvisionPolicy(agreementData);

  useEffect(() => {
    if (isPolicyLoaded) {
      fundTransferEligibilityRequest.executeRequest();
    }
  }, [isPolicyLoaded]);

  const {
    isTransactionAllowed,
    isEligible,
    eligibilityError,
    isAuthorized: isAuthorizedForFundAllocation,
  } = verifyFundTransfer({
    agreementData,
    producersData: producerRequest.data,
    fundTransferEligibilityData: fundTransferEligibilityRequest.data,
    transactionSupportedPolicies,
    user,
  });

  useEffect(() => {
    setFundAllocationEligibilityError(eligibilityError);
  }, [eligibilityError]);

  return {
    isTransactionAllowed,
    isEligible,
    isUnauthorized,
    isPolicyNotFound,
    isPolicyLoaded,
    isMmsdOnlyUser,
    showEnvisionPage,
    agreementRequest,
    producerRequest,
    pageNumber,
    setPageNumber,
    nextPage,
    previousPage,
    fundTransferEligibilityRequest,
    fundAllocationEligibilityError,
    isAuthorizedForFundAllocation,
    lineOfBusinessCode,
  };
};
