import React from "react";
import IsTruthy from "../../../../common/logic/isTruthy";
import { isTruthy } from "../../../../../utils/evaluate";
import IsFalsy from "../../../../common/logic/isFalsy";
import { useLifePolicyInfoProps } from "../hooks";

const LifePolicyInfo = (props) => {
  const {
    // modal: { showServicingAgentModal },
    data: {
      productTypeName,
      servicingAgent,
      servicingAgentName,
      faceAmount,
      issueDateTitle,
      issueDate,
      insuredPeople,
    },
  } = useLifePolicyInfoProps(props);
  const insuredList = insuredPeople.map((person, index) => {
    const { fullName } = person;
    return (
      <div id={`insured_name_${index}`} key={fullName}>
        {fullName}
        <br />
      </div>
    );
  });

  return (
    <div>
      <dl className="mm-key-value-pair--summation">
        <dt id="insured_name_lbl">Insured Name</dt>
        <dd id="insured_name_value">
          <IsTruthy value={insuredList.length > 0}>{insuredList}</IsTruthy>
          <IsTruthy value={insuredList.length === 0}>-</IsTruthy>
        </dd>
        <dt className="hide-print" id="product_name_lbl">
          Product Name
        </dt>
        <dd className="hide-print" id="product_name_value">
          {productTypeName}
        </dd>
        <dt className="hide-print" id="servicing_agent_name_lbl">
          Servicing Agent
        </dt>
        <dd className="hide-print" id="servicing_agent_full_name">
          <IsTruthy
            value={servicingAgent.validAgent}
            validationFunction={isTruthy}
          >
            {/* <button
              id="servicing_agent_name_btn"
              onClick={showServicingAgentModal(servicingAgent)}
              className="btn btn-inline"
              type="button"
            > */}
            {servicingAgentName}
            {/* </button> */}
          </IsTruthy>
          <IsFalsy
            value={servicingAgent.validAgent}
            validationFunction={isTruthy}
          >
            -
          </IsFalsy>
        </dd>
        <dt className="hide-print" id="face_amount_lbl">
          Face Amount
        </dt>
        <dd className="hide-print" id="face_amount_value">
          {faceAmount}
        </dd>
        <dt className="hide-print" id="issue_date_display_title_lbl">
          {issueDateTitle}
        </dt>
        <dd className="hide-print" id="issue_date_display_value">
          {issueDate}
        </dd>
      </dl>
    </div>
  );
};

export default React.memo(LifePolicyInfo);
