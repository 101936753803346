import useDeepCompareEffect from "use-deep-compare-effect";
import moment from "moment";
import "moment-timezone";
import { useEffect, useState } from "react";
import { useMakeRequest } from "../../../../hooks/api";
import {
  getBpIdFromUser,
  getFirstServicingAgent,
  getRequestorInfo,
  getUsers,
  isHomeOfficeUser,
} from "../../../../utils/findData";
import { useUserState } from "../../../../contexts/user";
import { useChooseRequestTypeFields } from "./requestInformationFormFields";
import {
  compareArrayOfStrings,
  compareStrings,
  stringIsEmptyOrNullish,
} from "../../../../utils/string";
import {
  isULUVPolicy,
  isTermPolicy,
  isPE1NTLVL,
  removeOwnerAndInsuredDuplicates,
  isDisabilityPolicy,
  verifyRequestInformation,
  isCoverpathPolicy,
} from "../../../../utils/policies";
import {
  extractNumberFromString,
  formatMoney,
  getLastXChars,
} from "../../../../utils/format";
import { useFormContext, types } from "../../../../contexts/form";
import {
  formatNetDeathBenefitAmount,
  displayUnsupportedCashValueMessage,
} from "../../sections/detail/hooks";
import { checkComplexBeneArrangement } from "../../sections/peopleOnPolicy/hooks";
import { useLDValue } from "../../../common/featureFlags/hooks";
import {
  AGREEMENT_NOT_FOUND,
  DATA_UNAVAILABLE,
} from "../../../../constants/ui";
import { isDefined } from "../../../../utils/evaluate";
import {
  formatDate,
  isAfterDate,
  subtractYearstoDate,
} from "../../../../utils/dates";
import { typesOfAgents } from "../../../../data/typesOfAgents";
import { datePickerInputValidation } from "../../../../utils/validation";

export const useRequestInformationProps = (agreementKey) => {
  const validRequest = (dependentAgreementData) => {
    const { isUnauthorized: isUnauthorizedAgreement } = dependentAgreementData;
    const {
      type: agreementMessageType,
      description: agreementMessageDescription,
    } = dependentAgreementData?.messages || {};
    return (
      !isUnauthorizedAgreement &&
      compareStrings(agreementMessageType, "SUCCESS") &&
      !compareStrings(agreementMessageDescription, AGREEMENT_NOT_FOUND)
    );
  };

  const producersRequest = useMakeRequest({
    apiRequest: "fetchProducersData",
    apiParams: { agreementKey },
    immediateRequest: false,
    dataSelector: "processProducersData",
    canExecuteCallBack: validRequest,
  });

  const agreementRequest = useMakeRequest({
    apiRequest: "fetchAgreementData",
    apiParams: { agreementKey },
    immediateRequest: true,
    dataSelector: "processAgreementRequest",
    dependentRequests: [producersRequest],
  });

  const { data: producersData } = producersRequest;
  const servicingAgent = getFirstServicingAgent({
    agents: producersData?.producers,
  });

  const { user } = useUserState();
  const { agentType } = getBpIdFromUser(user);
  const isMmsdOnlyUser = compareStrings(agentType, typesOfAgents.MMSD);

  const agreementData = agreementRequest?.data || {};
  const { agreementCustomers, policyId, lineOfBusinessCode, isPolicyNotFound } =
    agreementData;

  const transactionSupportedPolicies = useLDValue({
    flagName: ["transactionSupportedPolicies"],
  });

  const owners = getUsers(agreementCustomers, "OWNR") || [];
  const insured = getUsers(agreementCustomers, "INSD") || [];
  const productTypeName = agreementData?.productTypeName || "-";

  const shouldDisableSubmit = isHomeOfficeUser(user);

  const fields = useChooseRequestTypeFields();
  const [confirmationPage, setConfirmationPage] = useState(false);
  const [confirmationProps, setConfirmationProps] = useState({});
  const [showErrorPage, setShowErrorPage] = useState(false);
  const [transactionId, setTransactionId] = useState("");
  const [error, setError] = useState(null);

  const IS_UNAUTHORIZED = agreementData.isUnauthorized;
  const isPolicyLoaded = isDefined(policyId) && isDefined(lineOfBusinessCode);

  const { redirectLink, isEligible, isTransactionAllowed } =
    verifyRequestInformation({
      isMmsdOnlyUser,
      policyId,
      agreementData,
      transactionSupportedPolicies,
    });

  return {
    servicingAgent,
    producersData,
    confirmationProps,
    setConfirmationProps,
    confirmationPage,
    setConfirmationPage,
    agreementRequest,
    owners: removeOwnerAndInsuredDuplicates(owners),
    insured: removeOwnerAndInsuredDuplicates(insured),
    productTypeName,
    policyId,
    fields,
    showErrorPage,
    setShowErrorPage,
    transactionId,
    setTransactionId,
    shouldDisableSubmit,
    IS_UNAUTHORIZED,
    email: user?.email,
    producersRequest,
    error,
    setError,
    isMmsdOnlyUser,
    redirectLink,
    isEligible,
    isTransactionAllowed,
    isPolicyLoaded,
    isPolicyNotFound,
  };
};

export const useDetermineFieldsToShow = (agreementRequest, agreementKey) => {
  const { formData, dispatchForm } = useFormContext();
  const {
    data: {
      issueDate,
      jurisdictionState,
      netDeathBenefitAmount,
      agreementKeyAdmin,
      totalAccountValue,
      netSurrenderAmount,
      paidUpAddsAvailableAmount,
      paidUpAdditionsFaceAmount,
      dividendAccumAvailableAmount,
      maximumAvailableLoanAmount,
      currentAdditionsPurchasedAmount,
      status,
      statusReason,
      specialConditionCode,
      specialCondition2Code,
      agreementCoverages,
      loanPayoffAmount,
      lineOfBusinessCode,
    },
  } = agreementRequest;
  const fieldsToShow = {};
  const billingRequest = useMakeRequest({
    apiRequest: "fetchBillingInformation",
    apiParams: { agreementKey },
    dataSelector: "processBillingInformation",
    dataForSelectors: {
      issueDate,
      jurisdictionState,
    },
    immediateRequest: true,
  });

  const beneficiaryRequest = useMakeRequest({
    apiRequest: "fetchBeneficiaries",
    apiParams: { agreementKey },
    immediateRequest: false,
    dataSelector: "processBeneficiaryRequest",
  });
  const IS_DISABILITY_POLICY = isDisabilityPolicy({ lineOfBusinessCode });

  const isPolicyHap = isCoverpathPolicy(agreementKeyAdmin);

  useEffect(() => {
    if (!isPolicyHap && !IS_DISABILITY_POLICY) {
      beneficiaryRequest.executeRequest();
    }
  }, [isPolicyHap, IS_DISABILITY_POLICY]);

  const isTermOrULVL =
    isTermPolicy({ agreementRequest }) || isULUVPolicy({ agreementRequest });

  const REQUEST_INFO_UNAVAILABLE =
    status !== "IF" || compareStrings(statusReason, "DP");

  const HAS_LTCIR =
    agreementCoverages.filter((item) => compareStrings(item.type, "LTCIR"))
      .length > 0;

  const NET_CASH_AND_SURRENDER_VAL_SPECIAL_CONDITION =
    compareStrings(agreementKeyAdmin, "VNTG1") &&
    HAS_LTCIR &&
    (compareStrings(specialCondition2Code, "LTCCL") ||
      compareStrings(specialConditionCode, "LTCCL "));

  if (!REQUEST_INFO_UNAVAILABLE) {
    fieldsToShow.estimatedDeathBenefit = !!stringIsEmptyOrNullish(
      formatNetDeathBenefitAmount({ netDeathBenefitAmount, agreementKeyAdmin })
    );
    fieldsToShow.totalAccountValue = !!(
      isULUVPolicy({ agreementRequest }) &&
      stringIsEmptyOrNullish(formatMoney(totalAccountValue))
    );
    fieldsToShow.netCashValue = !!(
      !isULUVPolicy({ agreementRequest }) &&
      !isTermPolicy({ agreementRequest }) &&
      stringIsEmptyOrNullish(netSurrenderAmount) &&
      !NET_CASH_AND_SURRENDER_VAL_SPECIAL_CONDITION
    );
    fieldsToShow.surrenderValue = !!(
      !isTermPolicy({ agreementRequest }) &&
      stringIsEmptyOrNullish(netSurrenderAmount) &&
      !NET_CASH_AND_SURRENDER_VAL_SPECIAL_CONDITION
    );
    fieldsToShow.monthEndCashValue = !!displayUnsupportedCashValueMessage({
      agreementRequest,
    });
    fieldsToShow.paidUpAdditionsAvailable = showPaidUpAdditionsAvailable({
      paidUpAddsAvailableAmount,
      isTermOrULVL,
      loanPayoffAmount,
      lineOfBusinessCode,
      agreementKeyAdmin,
    });

    fieldsToShow.paidUpAdditionsFaceAmount = showPaidUpAdditionsFaceAmount({
      paidUpAddsAvailableAmount,
      paidUpAdditionsFaceAmount,
      isTermOrULVL,
      loanPayoffAmount,
      lineOfBusinessCode,
      agreementKeyAdmin,
    });

    fieldsToShow.paidUpAdditionsFaceAmountCurrentYear =
      !!stringIsEmptyOrNullish(currentAdditionsPurchasedAmount) &&
      !isTermOrULVL;
    fieldsToShow.dividendAccumulationsAvailable =
      !!stringIsEmptyOrNullish(dividendAccumAvailableAmount) && !isTermOrULVL;
    fieldsToShow.partialSurrenderValue =
      !isPE1NTLVL({ agreementRequest }) && isULUVPolicy({ agreementRequest });
    fieldsToShow.maxLoanAmount =
      !!stringIsEmptyOrNullish(maximumAvailableLoanAmount) &&
      !isTermPolicy({ agreementRequest });
    fieldsToShow.loanPayoffAmount = showLoanPayoffAmount(
      billingRequest,
      agreementRequest,
      billingRequest?.data?.loan?.twoDayAdvancedPayoffAmount
    );
    fieldsToShow.futureLoanPayoffAmount = showLoanPayoffAmount(
      billingRequest,
      agreementRequest,
      billingRequest?.data?.loan?.advancedPayoffAmount
    );
    fieldsToShow.beneficiary = !!showBeneficiary(beneficiaryRequest);
  }

  // only show request information for unavailable fields
  useDeepCompareEffect(() => {
    Object.keys(fieldsToShow).forEach((element) => {
      dispatchForm({
        type: types.REPLACE_FIELD,
        formField: {
          ...formData[element],
          show: fieldsToShow[element],
        },
      });
    });
  }, [fieldsToShow]);
};

export const showPaidUpAdditionsAvailable = ({
  paidUpAddsAvailableAmount,
  isTermOrULVL,
  loanPayoffAmount,
  lineOfBusinessCode,
  agreementKeyAdmin,
}) =>
  !!(
    stringIsEmptyOrNullish(paidUpAddsAvailableAmount) ||
    (compareStrings("LIFE", lineOfBusinessCode) &&
      compareArrayOfStrings(["OPM", "MPR", "VNTG1"], agreementKeyAdmin) &&
      extractNumberFromString(paidUpAddsAvailableAmount, 0) > 0 &&
      extractNumberFromString(loanPayoffAmount, 0) > 0)
  ) && !isTermOrULVL;

export const showPaidUpAdditionsFaceAmount = ({
  paidUpAddsAvailableAmount,
  paidUpAdditionsFaceAmount,
  isTermOrULVL,
  loanPayoffAmount,
  lineOfBusinessCode,
  agreementKeyAdmin,
}) =>
  !!(
    stringIsEmptyOrNullish(paidUpAdditionsFaceAmount) ||
    (compareStrings("LIFE", lineOfBusinessCode) &&
      compareArrayOfStrings(["OPM", "MPR", "VNTG1"], agreementKeyAdmin) &&
      extractNumberFromString(paidUpAddsAvailableAmount, 0) > 0 &&
      extractNumberFromString(loanPayoffAmount, 0) > 0)
  ) && !isTermOrULVL;

const showBeneficiary = (beneficiaryRequest) =>
  checkComplexBeneArrangement(beneficiaryRequest?.data?.beneficiaryArray);

const showLoanPayoffAmount = (billingRequest, agreementRequest, checkValue) => {
  const zeros = [0, "0", "$0.00", "Unavailable", null, undefined];
  const loanPrincipal = stringIsEmptyOrNullish(
    billingRequest?.data?.loan?.outstandingPrinicipalBalance
  )
    ? DATA_UNAVAILABLE
    : formatMoney(billingRequest?.data?.loan?.outstandingPrinicipalBalance);
  const payoffBalance = stringIsEmptyOrNullish(
    billingRequest?.data?.loan?.twoDayAdvancedPayoffAmount
  )
    ? DATA_UNAVAILABLE
    : formatMoney(billingRequest?.data?.loan?.twoDayAdvancedPayoffAmount);
  const loanInterestAmount = stringIsEmptyOrNullish(
    billingRequest?.data?.loan?.loanInterestAmount
  )
    ? DATA_UNAVAILABLE
    : formatMoney(billingRequest?.data?.loan?.loanInterestAmount);
  const loanPayoffSectionDisplay =
    zeros.includes(loanPrincipal) &&
    zeros.includes(payoffBalance) &&
    zeros.includes(loanInterestAmount);

  return (
    !!stringIsEmptyOrNullish(checkValue) &&
    !isTermPolicy({ agreementRequest }) &&
    !loanPayoffSectionDisplay
  );
};

export const useMakeCatsCreationCall = (props) => {
  const {
    agreementKey,
    producersData,
    formData,
    agreementRequest,
    insured,
    servicingAgent,
  } = props;
  const {
    contactChoiceEmailInput: { value: contactChoiceEmailInput },
    requestorNameInput: { value: requestorNameInput },
    requestInformationAsOfDate,
  } = formData;

  const requestHelper = {};

  // check input fields that are checkboxes that are showing that are checked except beneficiary
  const unavailableFields = Object.entries(formData).reduce(
    (accumulator, [, valueObject]) => {
      if (
        valueObject.type === "checkbox" &&
        valueObject.show === true &&
        valueObject.value === true &&
        valueObject.id !== "beneficiary"
      ) {
        return `${`${accumulator}  ${valueObject.label}`}\n`;
      }
      return accumulator;
    },
    ""
  );

  requestHelper.unavailableFields = unavailableFields;
  requestHelper.beneficiary =
    formData?.beneficiary?.show === true &&
    formData?.beneficiary?.value === true &&
    `  ${formData?.beneficiary?.label}\n`;
  requestHelper.transactionName = "requestInformation";

  const onlyPrimaryInsured = insured?.filter(
    (person) => person?.roleSubType === "PRMR"
  );
  const { homeAgencyId } = servicingAgent;
  const {
    firstName = "",
    lastName = "",
    middleName = "",
  } = onlyPrimaryInsured?.[0] || [];
  const userState = useUserState();
  const userName = userState?.user?.idToken?.claims?.IdNum;
  const idNumber = userName || "";
  const lengthOfAgencyId = 3;
  const isAnAgent =
    idNumber.toLowerCase().includes("aa") ||
    idNumber.toLowerCase().includes("ae");
  const orgUnit = isAnAgent
    ? getLastXChars(homeAgencyId, lengthOfAgencyId).padStart(
        lengthOfAgencyId,
        0
      )
    : "";
  const currentDate = moment().format("YYYY-MM-DD");
  const currentTimeLongComment = moment().format("M/D/YYYY h:mm:ss a");
  const receivedDateConfirmationPageFormat = moment().format("M/D/YYYY h:mm A");
  const zoneName = moment.tz.guess();
  const timezone = moment.tz(zoneName).zoneAbbr();
  const currentTimeLongCommentFormatted = `${currentTimeLongComment} ${timezone}`;
  const formattedAsOfDate = formatDate(
    requestInformationAsOfDate?.value?.toDateString(),
    "MM/DD/YY"
  );
  const longComment = `Contact Info: \n  Name: ${requestorNameInput}\n  Method: E-mail\n  Detail: ${contactChoiceEmailInput}\n\nOperator Name: ${requestorNameInput} ID: ${idNumber} Org Unit: ${orgUnit}\nSubmitted Date/Time: ${currentTimeLongCommentFormatted}\n\nUnavailable Value(s) Selected: \n{unavailableFields}\n\nSend Info To: \n  Name: ${requestorNameInput}\n  Method: E-mail\n  Detail: ${contactChoiceEmailInput}\n\n Provide quote as of date: ${formattedAsOfDate} `;
  const { requestorTypeCode } = getRequestorInfo({
    idNumber,
    producersData,
    agreementKey,
  });

  const requestBody = {
    priorityCode: "10",
    divCode: "CS",
    completionInd: "N",
    primaryLogDescription: "POL_NR",
    agreementTypeCode: "I",
    carrierAdminSystem: agreementRequest?.data?.agreementKeyAdmin,
    agreementNumber: agreementRequest?.data?.agreementKeyPrimary,
    receivedDate: currentDate,
    longComment,
    serviceChannelSourceCode: "19",
    shortComment: "Request Unavailable Information",
    requestorName: requestorNameInput,
    requestorTypeCode,
    deptCode: "TL",
    loggedByIdent: "CSPREF",
    insuredMiddleName: middleName,
    insuredFirstName: firstName,
    insuredLastName: lastName,
    requestHelper,
  };

  return {
    requestBody,
    longComment,
    user: userState?.user,
    confirmationDate: receivedDateConfirmationPageFormat,
  };
};

export const useConfirmationProps = (props) => {
  const { confirmationDate, insured, formData } = props;
  const {
    contactChoiceEmailInput: { value: contactChoiceEmailInput },
    requestorNameInput: { value: requestorNameInput },
  } = formData;

  const selectedCheckboxes = [];
  Object.keys(formData).forEach((key) => {
    if (formData[key]?.type === "checkbox" && formData[key]?.value === true) {
      selectedCheckboxes.push(formData[key].label);
    }
  });

  const formattedSelectedCheckboxes = selectedCheckboxes.map((item, index) => {
    if (index !== selectedCheckboxes.length - 1) {
      return `${item}, `;
    }
    return item;
  });

  const formattedInsured = insured.map((person, index) => {
    if (index !== insured.length - 1) {
      return `${person.fullName}, `;
    }
    return `${person.fullName}`;
  });

  const zoneName = moment.tz.guess();
  const timezone = moment.tz(zoneName).zoneAbbr();
  const formattedDate = `${confirmationDate} ${timezone}`;

  return {
    insured: formattedInsured,
    selectedCheckboxes: formattedSelectedCheckboxes,
    requestorEmail: contactChoiceEmailInput,
    requestorName: requestorNameInput,
    receivedDate: formattedDate,
  };
};

export const useSelectRequestInformationAsOfDate = (props) => {
  const { agreementRequest } = props;
  const { issueDate } = agreementRequest.data;
  const [selectedDate, setSelectedDate] = useState(new Date());

  const { formData, dispatchForm } = useFormContext();
  const { requestInformationAsOfDate } = formData;
  const oneYearBackDate = subtractYearstoDate(new Date(), 1);

  const datePickerMinDate = isAfterDate(issueDate, oneYearBackDate)
    ? new Date(issueDate.replace(/-/g, "/"))
    : oneYearBackDate;
  const datePickerMaxDate = new Date();

  const dateHandler = (date) => {
    setSelectedDate(date);
    if (isDefined(requestInformationAsOfDate) && isDefined(dispatchForm)) {
      dispatchForm({
        type: types.REPLACE_FIELD,
        formField: {
          ...requestInformationAsOfDate,
          value: date,
          postpone: false,
          minDate: datePickerMinDate,
          maxDate: datePickerMaxDate,
        },
      });
    }
  };

  return {
    requestInformationAsOfDate,
    datePickerMaxDate,
    datePickerMinDate,
    selectedDate,
    dateHandler,
    invalidDate: datePickerInputValidation({
      selectedDate,
      datePickerMinDate,
      datePickerMaxDate,
    }),
  };
};
