import { useParams } from "react-router-dom";
import React, { useEffect } from "react";
import { createDateObject, formatDate } from "../../../../utils/dates";
import {
  compareStrings,
  compareArrayOfStrings,
} from "../../../../utils/string";
import { useMakeRequest } from "../../../../hooks/api";
import {
  formatMoney,
  formatName,
  nameFormattingOptions,
  formatTransactionDescription,
} from "../../../../utils/format";
import CashCell from "../../../common/table/customCells/cash";
import { isDefined } from "../../../../utils/evaluate";
import { getUserBySubType, userTypes } from "../../../../utils/findData";
import { findRidersWithPremiums } from "../../sections/billing/hooks";
import { useUserState } from "../../../../contexts/user";
import { AGREEMENT_NOT_FOUND } from "../../../../constants/ui";
import {
  isArtistryAnnuityProduct,
  isDisabilityPolicy,
  isEnvisionPolicy,
  isOdysseyAnnuityProduct,
  isPallmPolicy,
  isRetireEaseContract,
  isVariableAnnuity,
  isAnnuityContract,
  isFixedAnnuity,
} from "../../../../utils/policies";
import { getJointOwnerInfo } from "../../hooks";
import { useHideEdsDiAsOfDate } from "../../../../utils/dataMasking";

export const formatFrequency = (props, defaultReturn = "-") => {
  const { reversalInd, frequency } = props;
  if (reversalInd === "Y") {
    return "Adjustment";
  }
  if (reversalInd === "N" && isDefined(frequency)) {
    return frequency;
  }
  return defaultReturn;
};

export const tableColumns = (
  adminKey,
  lineOfBusinessCode,
  agreementRequest,
  agreementKeyPrefix
) => {
  const premium = {
    accessor: "premiumDue",
    label: "Premium",
    alignment: "left",
    sortable: "Number",
    CustomCell: CashCell,
  };
  const interest = {
    accessor: "interestDue",
    label: "Interest",
    alignment: "left",
    CustomCell: CashCell,
    sortable: "Number",
  };
  const totalPayment = {
    accessor: "totalPayment",
    label: [
      <React.Fragment key="total-payment">
        Total <br /> Payment
      </React.Fragment>,
    ],
    alignment: "left",
    CustomCell: CashCell,
    sortable: "Number",
  };
  const paymentByABR = {
    accessor: "paymentByABR",
    label: [
      <React.Fragment key="payment-by-ABR">
        Payment <br /> By ABR
      </React.Fragment>,
    ],
    alignment: "left",
    sortable: "Number",
  };
  const totalAmountDue = {
    accessor: "totalPayment",
    label: [
      <React.Fragment key="total-amount-due">
        Total <br /> Amount Due
      </React.Fragment>,
    ],
    alignment: "left",
    CustomCell: CashCell,
    sortable: "Number",
  };
  const transaction = {
    accessor: "transaction",
    label: "Transaction",
    alignment: "left",
    sortable: "String",
  };
  const dueDate = {
    accessor: "dueDate",
    label: "Due Date",
    alignment: "left",
    dataCell: true,
    sortable: "Date",
  };
  const submissionDate = {
    accessor: "submissionDate",
    label: "Process Date",
    alignment: "left",
    sortable: "Date",
  };
  const frequency = {
    accessor: "frequency",
    label: "Frequency",
    alignment: "left",
    sortable: "String",
  };

  const paymentByCash = {
    accessor: "paymentByCash",
    label: [
      <React.Fragment key="payment-by-cash">
        Payment <br /> By Cash
      </React.Fragment>,
    ],
    alignment: "left",
    CustomCell: CashCell,
    sortable: "Number",
  };
  const paymentByDivd = {
    accessor: "paymentByDivd",
    label: [
      <React.Fragment key="payment-by-dividend">
        Payment <br /> By Dividend
      </React.Fragment>,
    ],
    alignment: "left",
    CustomCell: CashCell,
    sortable: "Number",
  };
  const paymentByLoan = {
    accessor: "paymentByLoan",
    label: [
      <React.Fragment key="payment-by-loan">
        Payment <br /> By Loan
      </React.Fragment>,
    ],
    alignment: "left",
    CustomCell: CashCell,
    sortable: "Number",
  };
  const effectiveDate = {
    accessor: "effectiveDate",
    label: "Effective Date",
    alignment: "left",
    dataCell: true,
    sortable: "Date",
  };
  const amount = {
    accessor: "totalPayment",
    label: "Amount",
    alignment: "left",
    CustomCell: CashCell,
    sortable: "Number",
  };
  const reversal = {
    accessor: "reversal",
    label: "Reversal",
    alignment: "left",
    sortable: "String",
  };
  const contributionYear = {
    accessor: "contributionTaxYear",
    label: "Contribution Year",
    alignment: "left",
    sortable: "Number",
  };

  const employeeEmployer = {
    accessor: "employeeEmployer",
    label: "Employee/Employer",
    alignment: "left",
    sortable: "String",
  };

  const opmSubmissionDateColumn = {
    ...submissionDate,
    dataCell: true,
  };
  const OPMcolumns = [
    opmSubmissionDateColumn,
    dueDate,
    frequency,
    premium,
    interest,
    paymentByCash,
    paymentByDivd,
    paymentByLoan,
    totalPayment,
  ];
  const MPRcolumns = [
    submissionDate,
    dueDate,
    frequency,
    premium,
    interest,
    paymentByCash,
    paymentByDivd,
    paymentByABR,
    paymentByLoan,
    totalPayment,
  ];

  const VNTG1columns = [
    submissionDate,
    dueDate,
    transaction,
    frequency,
    totalAmountDue,
    paymentByCash,
    paymentByDivd,
    paymentByLoan,
  ];
  const PE1columns = [
    submissionDate,
    effectiveDate,
    transaction,
    amount,
    reversal,
  ];
  const DIcolumns = [submissionDate, dueDate, frequency, premium, totalPayment];
  const variableAnnuityAndOdysseyColumns = [
    submissionDate,
    effectiveDate,
    contributionYear,
    transaction,
    amount,
  ];
  const artistryAnnuityColumns = [
    submissionDate,
    effectiveDate,
    contributionYear,
    employeeEmployer,
    transaction,
    amount,
  ];

  const retireEaseAndChoicePolicyColumns = [
    submissionDate,
    effectiveDate,
    transaction,
    amount,
  ];

  if (isDisabilityPolicy({ lineOfBusinessCode })) return DIcolumns;
  if (
    (isVariableAnnuity(agreementRequest) &&
      !isArtistryAnnuityProduct(agreementKeyPrefix)) ||
    isOdysseyAnnuityProduct(agreementKeyPrefix)
  )
    return variableAnnuityAndOdysseyColumns;
  if (isArtistryAnnuityProduct(agreementKeyPrefix))
    return artistryAnnuityColumns;
  if (isRetireEaseContract(agreementRequest?.data))
    return retireEaseAndChoicePolicyColumns;

  switch (adminKey) {
    case "MPR":
      return MPRcolumns;
    case "OPM":
      return OPMcolumns;
    case "VNTG1":
      return VNTG1columns;
    case "PE1":
      return PE1columns;
    default:
      return [];
  }
};

export const tableRows = (props) => {
  const {
    financialHistory: financialHistories,
    financialHistoryAvailable,
    adminSystemCode,
    lineOfBusinessCode,
    agreementRequest,
    additionalFinancialHistory,
    additionalFinancialHistoryAvailable,
    marketType,
  } = props;
  const getOPMrows = (item, index) => {
    const {
      dueDate,
      submissionDate,
      premiumDue,
      interestDue,
      paymentByCash,
      paymentByDivd,
      paymentByLoan,
      totalPayment,
      reversalInd,
      frequency,
    } = item;
    const formattedTotalPayment =
      reversalInd === "Y" ? -totalPayment : totalPayment;
    return {
      id: index,
      dueDate: formatDate(dueDate, "monthCommaDayYear", "-"),
      submissionDate: formatDate(submissionDate, "monthCommaDayYear", "-"),
      frequency: formatFrequency({ reversalInd, frequency }),
      premiumDue: formatMoney(premiumDue),
      interestDue: formatMoney(interestDue),
      paymentByCash: formatMoney(paymentByCash),
      paymentByDivd: formatMoney(paymentByDivd),
      paymentByLoan: formatMoney(paymentByLoan),
      totalPayment: formatMoney(formattedTotalPayment),
    };
  };
  const getVNTG1rows = (item, index) => {
    const {
      dueDate,
      submissionDate,
      transactionCode,
      adminTransactionDesc,
      premiumDue,
      paymentByCash,
      paymentByDivd,
      paymentByLoan,
      reversalInd,
      frequency,
      totalPayment,
    } = item;
    const formattedPremiumDue = reversalInd === "Y" ? -premiumDue : premiumDue;
    const formattedPaymentByCash =
      reversalInd === "Y" ? -paymentByCash : paymentByCash;
    const formattedPaymentByDivd =
      reversalInd === "Y" ? -paymentByDivd : paymentByDivd;
    const formattedPaymentByLoan =
      reversalInd === "Y" ? -paymentByLoan : paymentByLoan;
    const formattedTransactionDesc =
      transactionCode === "NA" ? "Premium Payment" : adminTransactionDesc;
    return {
      id: index,
      dueDate: formatDate(dueDate, "monthCommaDayYear", "-"),
      submissionDate: formatDate(submissionDate, "monthCommaDayYear", "-"),
      transaction: formattedTransactionDesc,
      frequency: formatFrequency({ reversalInd, frequency }),
      premiumDue: formatMoney(formattedPremiumDue),
      paymentByCash: formatMoney(formattedPaymentByCash),
      paymentByDivd: formatMoney(formattedPaymentByDivd),
      paymentByLoan: formatMoney(formattedPaymentByLoan),
      totalPayment: formatMoney(totalPayment),
    };
  };
  const getPE1Transaction = (transactionProps) => {
    const { transactionCode } = transactionProps;
    return formatTransactionDescription(transactionCode);
  };
  const getPE1rows = (item, index) => {
    const {
      effectiveDate,
      submissionDate,
      transactionCode,
      transactionDesc,
      totalPayment,
      reversalInd,
    } = item;
    const formattedReversal = reversalInd === "Y" ? reversalInd : "";

    const hideReturn = ["NL"];
    if (compareArrayOfStrings(hideReturn, transactionCode)) {
      return null;
    }

    return {
      id: index,
      effectiveDate: formatDate(effectiveDate, "monthCommaDayYear", "-"),
      submissionDate: formatDate(submissionDate, "monthCommaDayYear", "-"),
      transaction: getPE1Transaction({
        transactionCode,
        transactionDesc,
      }),
      totalPayment: formatMoney(totalPayment),
      reversal: formattedReversal,
    };
  };
  const getMPRrows = (item, index) => {
    const {
      dueDate,
      submissionDate,
      premiumDue,
      interestDue,
      paymentByCash,
      paymentByDivd,
      paymentByABR,
      paymentByLoan,
      totalPayment,
      reversalInd,
      frequency,
    } = item;
    const formattedTotalPayment =
      reversalInd === "Y" ? -totalPayment : totalPayment;
    return {
      id: index,
      dueDate: formatDate(dueDate, "monthCommaDayYear", "-"),
      submissionDate: formatDate(submissionDate, "monthCommaDayYear", "-"),
      frequency: formatFrequency({ reversalInd, frequency }),
      premiumDue: formatMoney(premiumDue),
      interestDue: formatMoney(interestDue),
      paymentByCash: formatMoney(paymentByCash),
      paymentByDivd: formatMoney(paymentByDivd),
      paymentByABR: formatMoney(paymentByABR),
      paymentByLoan: formatMoney(paymentByLoan),
      totalPayment: formatMoney(formattedTotalPayment),
    };
  };
  const getDIrows = (item, index) => {
    const {
      dueDate,
      submissionDate,
      premiumDue,
      totalPayment,
      reversalInd,
      frequency,
    } = item;
    const formattedTotalPayment =
      reversalInd === "Y" ? -totalPayment : totalPayment;
    return {
      id: index,
      dueDate: formatDate(dueDate, "monthCommaDayYear", "-"),
      submissionDate: formatDate(submissionDate, "monthCommaDayYear", "-"),
      frequency: formatFrequency({ reversalInd, frequency }),
      premiumDue: formatMoney(premiumDue),
      totalPayment: formatMoney(formattedTotalPayment),
    };
  };

  const getVariableAnnuityAndOdysseyRows = (item, index) => {
    const {
      submissionDate,
      effectiveDate,
      contributionTaxYear,
      transactionDesc,
      totalPayment,
    } = item;

    const contributionYear =
      !isDefined(contributionTaxYear) || contributionTaxYear === "9999"
        ? "-"
        : contributionTaxYear;

    return {
      id: index,
      submissionDate: formatDate(
        formatDate(submissionDate),
        "monthCommaDayYear",
        "-"
      ),
      effectiveDate: formatDate(
        formatDate(effectiveDate),
        "monthCommaDayYear",
        "-"
      ),
      // logic for contributionTaxYear below will also have to be implemented for Artistry rows when SVNT-4471 is merged to dev
      contributionTaxYear: compareStrings(marketType, "IRA")
        ? contributionYear
        : "Not Applicable",
      transaction: transactionDesc || "-",
      totalPayment: formatMoney(totalPayment, "-"),
    };
  };

  const getArtistryAnnuityEmployeeRows = (item, index) => {
    const {
      submissionDate,
      effectiveDate,
      contributionTaxYear,
      transactionDesc,
      totalPayment,
    } = item;

    const contributionYear =
      !isDefined(contributionTaxYear) || contributionTaxYear === "9999"
        ? "-"
        : contributionTaxYear;

    return {
      id: `EE${index}`,
      submissionDate: formatDate(submissionDate, "monthCommaDayYear", "-"),
      effectiveDate: formatDate(effectiveDate, "monthCommaDayYear", "-"),
      contributionTaxYear: compareStrings(marketType, "IRA")
        ? contributionYear
        : "Not Applicable",
      employeeEmployer: "Employee",
      transaction: transactionDesc || "-",
      totalPayment: formatMoney(totalPayment, "-"),
    };
  };

  const getArtistryAnnuityEmployerRows = (item, index) => {
    const {
      submissionDate,
      effectiveDate,
      contributionTaxYear,
      transactionDesc,
      totalPayment,
    } = item;

    const contributionYear =
      !isDefined(contributionTaxYear) || contributionTaxYear === "9999"
        ? "-"
        : contributionTaxYear;

    return {
      id: `ER${index}`,
      submissionDate: formatDate(submissionDate, "monthCommaDayYear", "-"),
      effectiveDate: formatDate(effectiveDate, "monthCommaDayYear", "-"),
      contributionTaxYear: compareStrings(marketType, "IRA")
        ? contributionYear
        : "Not Applicable",
      employeeEmployer: "Employer",
      transaction: transactionDesc || "-",
      totalPayment: formatMoney(totalPayment, "-"),
    };
  };

  const getRetireEaseAndChoicePolicyRows = (item, index) => {
    const { submissionDate, effectiveDate, transactionDesc, totalPayment } =
      item;

    return {
      id: index,
      submissionDate: formatDate(submissionDate, "monthCommaDayYear", "-"),
      effectiveDate: formatDate(effectiveDate, "monthCommaDayYear", "-"),
      transaction: isDefined(transactionDesc)
        ? getFinancialHistoryTransactionDescription(transactionDesc)
        : "-",
      totalPayment: formatMoney(totalPayment, "-"),
    };
  };

  if (financialHistoryAvailable) {
    // first priority Line Of Business then admin system
    if (isDisabilityPolicy({ lineOfBusinessCode })) {
      return financialHistories.map(getDIrows);
    }

    if (
      (isVariableAnnuity(agreementRequest) &&
        !isArtistryAnnuityProduct(
          agreementRequest?.data?.agreementKeyPrefix
        )) ||
      isOdysseyAnnuityProduct(agreementRequest?.data?.agreementKeyPrefix)
    ) {
      return financialHistories.map(getVariableAnnuityAndOdysseyRows);
    }
    if (isRetireEaseContract(agreementRequest?.data)) {
      return financialHistories.map(getRetireEaseAndChoicePolicyRows);
    }

    if (isArtistryAnnuityProduct(agreementRequest?.data?.agreementKeyPrefix)) {
      if (additionalFinancialHistoryAvailable) {
        const initialHistory = financialHistories.map(
          getArtistryAnnuityEmployeeRows
        );
        const additionalHistory = additionalFinancialHistory.map(
          getArtistryAnnuityEmployerRows
        );
        const artistryHistory = initialHistory.concat(additionalHistory);

        return artistryHistory.sort((a, b) => {
          const dateA = createDateObject(a.submissionDate);
          const dateB = createDateObject(b.submissionDate);
          return dateA - dateB;
        });
      }
      return financialHistories.map(getArtistryAnnuityEmployeeRows);
    }

    switch (adminSystemCode) {
      case "MPR":
        return financialHistories.map(getMPRrows);
      case "OPM":
        return financialHistories.map(getOPMrows);
      case "VNTG1":
        return financialHistories.map(getVNTG1rows);
      case "PE1":
        return financialHistories.map(getPE1rows);
      default:
        return [];
    }
  }
  return [];
};

export const useTable = (props) => {
  const {
    apiRequest,
    apiParams,
    dataSelector,
    agreementRequest,
    isVariableAnnuityContract,
    isArtistryAnnuityContract,
    annuityRequest,
  } = props;
  const requestInfo = useMakeRequest({
    apiRequest,
    apiParams,
    dataSelector,
    immediateRequest: false,
    defaultReturn: {},
  });
  const additionalRequestInfo = useMakeRequest({
    apiRequest,
    apiParams: {
      agreementKey: apiParams?.agreementKey?.includes("EE")
        ? apiParams?.agreementKey?.replace("EE", "ER")
        : apiParams?.agreementKey?.replace("ER", "EE"),
    },
    dataSelector,
    immediateRequest: false,
    defaultReturn: {},
  });

  const { isUnauthorized, messages, lineOfBusinessCode, agreementKeyPrefix } =
    agreementRequest?.data || {};

  const { marketType } = annuityRequest?.data || "";

  useEffect(() => {
    if (
      !isUnauthorized &&
      compareStrings(messages.type, "SUCCESS") &&
      !compareStrings(messages.description, AGREEMENT_NOT_FOUND)
    ) {
      if (isArtistryAnnuityContract) {
        requestInfo.executeRequest();
        additionalRequestInfo.executeRequest();
      } else {
        requestInfo.executeRequest();
      }
    }
  }, [isUnauthorized, messages.type]);

  const {
    data: { adminSystemCode, financialHistory, financialHistoryAvailable },
  } = requestInfo;

  const filteredFinancialHistory = financialHistory?.filter(
    (history) => history.adminTransactionDesc === "PAYMENT TRX"
  );

  const {
    data: {
      financialHistory: additionalFinancialHistory,
      financialHistoryAvailable: additionalFinancialHistoryAvailable,
    },
  } = additionalRequestInfo;

  const columns = tableColumns(
    adminSystemCode,
    lineOfBusinessCode,
    agreementRequest,
    agreementKeyPrefix
  );
  const rows = tableRows({
    financialHistory: isVariableAnnuityContract
      ? filteredFinancialHistory
      : financialHistory,
    adminSystemCode,
    financialHistoryAvailable,
    lineOfBusinessCode,
    agreementRequest,
    additionalFinancialHistory: additionalFinancialHistory?.filter(
      (history) => history.adminTransactionDesc === "PAYMENT TRX"
    ),
    additionalFinancialHistoryAvailable,
    marketType,
  }).filter((row) => isDefined(row));

  return {
    requestInfo,
    additionalRequestInfo,
    table: {
      columns,
      rows,
    },
  };
};

export const useFinancialHistoryProps = () => {
  const { agreementKey } = useParams();

  const validRequest = (dependentAgreementData) => {
    const { isUnauthorized } = dependentAgreementData;
    const { type, description } = dependentAgreementData?.messages || {};
    return (
      !isUnauthorized &&
      compareStrings(type, "SUCCESS") &&
      !compareStrings(description, AGREEMENT_NOT_FOUND)
    );
  };

  const billingRequest = useMakeRequest({
    apiRequest: "fetchBillingInformation",
    apiParams: { agreementKey, showExtended: false },
    dataSelector: "processBillingInformation",
    immediateRequest: false,
    updateRequestCallback: (dependentAgreementData) => {
      const { issueDate, jurisdictionState } = dependentAgreementData;
      const dataForSelectors = isDefined(issueDate)
        ? { jurisdictionState }
        : { issueDate, jurisdictionState };
      return { paramsForSelectors: dataForSelectors };
    },
    canExecuteCallBack: validRequest,
  });

  const agreementRequest = useMakeRequest({
    apiRequest: "fetchAgreementData",
    apiParams: { agreementKey },
    immediateRequest: true,
    dataSelector: "processAgreementRequest",
    dependentRequests: [billingRequest],
  });

  const agreementData = agreementRequest?.data;
  const {
    policyId,
    agreementKeyAdmin,
    asOfDate,
    isUnauthorized,
    productTypeCode,
    agreementKeyPrefix,
    isPolicyNotFound,
  } = agreementData;

  const contractNbr = policyId;
  const IS_ENVISION_POLICY = isEnvisionPolicy(agreementRequest.data);
  const isVariableAnnuityContract =
    isVariableAnnuity(agreementRequest) && !IS_ENVISION_POLICY;
  const isArtistryAnnuityContract =
    isArtistryAnnuityProduct(agreementKeyPrefix);

  const annuityRequest = useMakeRequest({
    apiRequest: "fetchAnnuityByContractNbr",
    apiParams: { contractNbr },
    immediateRequest: false,
    dataSelector: "processAnnuityData",
  });
  const isValidRequest = validRequest(agreementData);
  const shouldCallAnnuityRequest = isValidRequest && isVariableAnnuityContract;

  useEffect(() => {
    if (shouldCallAnnuityRequest) {
      annuityRequest.executeRequest();
    }
  }, [shouldCallAnnuityRequest]);

  const premiumObject = {
    agreementData: agreementRequest?.data,
    billingData: billingRequest?.data,
  };
  const siprOpmRiders = findRidersWithPremiums({
    riderType: "siprOpm",
    ...premiumObject,
  });
  const lisrOpmRiders = findRidersWithPremiums({
    riderType: "lisrOpm",
    ...premiumObject,
  });
  const alirOpmRiders = findRidersWithPremiums({
    riderType: "alirOpm",
    ...premiumObject,
  });
  const hasRiders =
    [...siprOpmRiders, ...lisrOpmRiders, ...alirOpmRiders].length > 0;

  const showFinancialhistoryPage = shouldShowFinancialHistory({
    agreementKeyAdmin,
    productTypeCode,
    hasRiders,
    IS_ENVISION_POLICY,
    isVariableAnnuityContract,
  });

  const tableProps = {
    apiRequest: "fetchFinancialHistory",
    apiParams: { agreementKey },
    dataSelector: "processFinancialHistoryRequest",
    agreementRequest,
    isVariableAnnuityContract,
    isArtistryAnnuityContract,
    annuityRequest,
  };
  const { table, requestInfo, variableAnnuityColumns, additionalRequestInfo } =
    useTable(tableProps);
  const { agreementCustomers } = agreementData || {};
  const { lineOfBusinessCode } = agreementData;

  const personInsured = isAnnuityContract(lineOfBusinessCode)
    ? getUserBySubType({
        type: userTypes.OWNER,
        subType: "LIST",
        people: agreementCustomers,
      })
    : getUserBySubType({
        type: userTypes.INSURED,
        subType: "PRMR",
        people: agreementCustomers,
      });
  const insuredName = isDefined(personInsured)
    ? formatName({
        nameObject: personInsured,
        format: nameFormattingOptions.firstNameDisplayedFirst,
      })
    : "";

  let defaultSortField;
  if (compareStrings(agreementKeyAdmin, "PE1")) {
    defaultSortField = "submissionDate";
  } else if (compareStrings(agreementKeyAdmin, "OPM")) {
    defaultSortField = "submissionDate";
  } else if (compareArrayOfStrings(["MPR", "VNTG1"], agreementKeyAdmin)) {
    defaultSortField = "submissionDate";
  }

  const hideEdsDiAsOfDate = useHideEdsDiAsOfDate({ agreementKeyAdmin });

  const {
    user: { email },
  } = useUserState();

  const { policyOrContract, nameLabel } =
    getLabelValuesByPolicyType(agreementRequest);

  const { jointOwnerNameLabel, jointOwnerName, displayJointOwner } =
    getJointOwnerInfo({ agreementCustomers, lineOfBusinessCode });
  return {
    requests: {
      agreementRequest,
      financialHistoryRequest: requestInfo,
      billingRequest,
      additionalFinancialHistoryRequest: additionalRequestInfo,
    },
    insuredName,
    asOfDate: formatDate(asOfDate, "monthCommaDayYear"),
    agreementKey,
    policyId,
    table,
    variableAnnuityColumns,
    defaultSortField,
    isUnauthorized,
    hasRiders,
    email,
    validRequest: isValidRequest,
    showFinancialhistoryPage,
    isVariableAnnuityContract,
    policyOrContract,
    nameLabel,
    jointOwnerNameLabel,
    jointOwnerName,
    displayJointOwner,
    isPolicyLoaded: isDefined(policyId),
    isPolicyNotFound,
    hideEdsDiAsOfDate,
  };
};

export const shouldShowFinancialHistory = ({
  agreementKeyAdmin,
  productTypeCode,
  IS_CONVERTED_HAP,
  hasRiders,
  IS_ENVISION_POLICY,
  lineOfBusinessCode,
  isArtistryAnnuityContract,
  IS_ANNUITY_AND_LIFCOM,
}) => {
  const isLIFCOMOrLVRGVL =
    compareStrings(agreementKeyAdmin, "LIFCOM") ||
    compareStrings(agreementKeyAdmin, "LVRGVL");
  const isSWLOrCM2 =
    compareStrings(agreementKeyAdmin, "VNTG1") &&
    (compareStrings(productTypeCode, "SWL") ||
      compareStrings(productTypeCode, "CM2"));

  const isOpmSIPOrLISOrALIOrRiders =
    compareStrings(agreementKeyAdmin, "OPM") && hasRiders;

  const isPallm = isPallmPolicy({ lineOfBusinessCode, agreementKeyAdmin });

  return !(
    (isLIFCOMOrLVRGVL ||
      isSWLOrCM2 ||
      IS_CONVERTED_HAP ||
      isOpmSIPOrLISOrALIOrRiders ||
      IS_ENVISION_POLICY ||
      isPallm ||
      isArtistryAnnuityContract) &&
    IS_ANNUITY_AND_LIFCOM === false
  );
};

export const getLabelValuesByPolicyType = (agreementRequest) => {
  let nameLabel;
  const isVariableAnnuityContract = isVariableAnnuity(agreementRequest);
  const isFixedAnnuityContract = isFixedAnnuity(agreementRequest);
  const isRetireEaseContractResult = isRetireEaseContract(
    agreementRequest?.data
  );
  const policyOrContract =
    isVariableAnnuityContract ||
    isRetireEaseContractResult ||
    isFixedAnnuityContract
      ? "Contract"
      : "Policy";

  if (isAnnuityContract(agreementRequest.data.lineOfBusinessCode)) {
    nameLabel = "Owner Name";
  } else {
    nameLabel = "Insured Name";
  }

  const returnObject = {
    policyOrContract,
    nameLabel,
  };

  return returnObject;
};

export const getFinancialHistoryTransactionDescription = (transactionDesc) => {
  let transactionDescription = "";

  switch (transactionDesc.toLowerCase().trim()) {
    case "annuity payment":
      transactionDescription = "Periodic Payment";
      break;
    case "premium payment":
      transactionDescription = "Contributions";
      break;
    default:
      transactionDescription = transactionDesc;
  }

  return transactionDescription;
};
