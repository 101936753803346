/* eslint-disable no-param-reassign */
import { useEffect, useState, useMemo } from "react";
import useDeepCompareEffect from "use-deep-compare-effect";
import cloneDeep from "lodash.clonedeep";
import { compareDesc } from "date-fns";
import {
  getCurrentDate,
  formatDate,
  createDateObject,
} from "../../utils/dates";
import Links from "../common/table/customCells/links";
import OwnerButtonCell from "../common/table/customCells/ownerButtonCell";
import QuickLinks from "./quickLinks";
import { useMakeRequest } from "../../hooks/api";
import {
  compareStrings,
  stringIsEmptyOrNullish,
  compareArrayOfStrings,
  isNonEmptyString,
} from "../../utils/string";
import { isDefined, isNaN } from "../../utils/evaluate";
import {
  formatName,
  nameFormattingOptions,
  formatStatusCode,
  handleDateUndefinedSorting,
  formatProductType,
} from "../../utils/format";
import {
  hasError,
  useGeneralValidation,
  usePhoneSearchValidation,
} from "../../utils/validation";
import { useFormContext, types } from "../../contexts/form";
import { formattingTypes } from "../../utils/formFormatting";
import { useUserState } from "../../contexts/user";
import {
  getSessionStorageObject,
  setSessionStorageObject,
} from "../../utils/dom";
import { filterRows } from "../../utils/table";
import { searchRows } from "../../utils/search";
import { getUser, getUsers, userTypes } from "../../utils/findData";
import {
  useGetFlagsValues,
  useIsFeatureOn,
  useLDValue,
} from "../common/featureFlags/hooks";
import { removeOwnerAndInsuredDuplicates } from "../../utils/policies";

export const setSessionData = (sessionData, user, filterOn) => {
  if (sessionData) {
    setSessionStorageObject({
      name: user.idToken,
      value: {
        ...sessionData,
        homePage: { ...sessionData.homePage, filteredPoliciesOn: !filterOn },
      },
    });
  } else {
    setSessionStorageObject({
      name: user.idToken,
      value: {
        homePage: { filteredPoliciesOn: !filterOn },
      },
    });
  }
};

export const formatRequestBody = (props) => {
  const {
    searchString,
    searchBy,
    agencyNumber,
    agentId,
    IS_HOME_OFFICE_USER,
    homeOfficeRadioSelection,
  } = props;

  const ADD_AGENTID_TO_BODY =
    IS_HOME_OFFICE_USER &&
    compareStrings(homeOfficeRadioSelection, "agentId") &&
    isNonEmptyString(agentId);
  const ADD_AGENCY_NUMBER_TO_BODY =
    IS_HOME_OFFICE_USER &&
    compareStrings(homeOfficeRadioSelection, "agencyNumber") &&
    isNonEmptyString(agencyNumber);

  let homeOfficeKey = {};
  if (ADD_AGENTID_TO_BODY) {
    homeOfficeKey = { bpId: `AA${agentId.padStart(6, "0")}` };
  }
  if (ADD_AGENCY_NUMBER_TO_BODY) {
    homeOfficeKey = { agencyId: agencyNumber.padStart(3, "0") };
  }

  let tenDigitNumber = searchString;

  switch (searchBy) {
    case "policyNumberGlobal":
      return { policyNum: searchString };
    case "policyNumber":
      return { policyNum: searchString, ...homeOfficeKey };
    case "phoneNumber":
      if (searchString.length === 11) {
        tenDigitNumber = searchString.substring(1, 11);
      }
      return { phoneNum: tenDigitNumber, ...homeOfficeKey };
    case "ssnOrTin":
      return { governmentId: searchString, ...homeOfficeKey };
    case "lastName":
      return { lastName: searchString.trim(), ...homeOfficeKey };
    case "fullName": {
      const { firstName, lastName } = searchString;
      return { lastName, firstName, ...homeOfficeKey };
    }
    case "trustOrOrganization":
      return { organization: searchString, ...homeOfficeKey };
    case "groupName":
      return { groupFullName: searchString, ...homeOfficeKey };
    case "apmNumber":
      return { apmNumber: searchString, ...homeOfficeKey };
    default:
      return {};
  }
};

export const formatAgentSearchResults = (props) => {
  const { policies, searchBy } = props;
  const formattedRows =
    policies?.map((row) => {
      const {
        policy: {
          policyNum,
          owner,
          owners = [],
          insured,
          productTypeCode,
          productTypeName,
          lineOfBusinessCode,
          statusCode,
          agreementStartDate,
          agreementSourceCode,
          ...rest
        },
      } = row;
      const personInsuredFullName = formatName({
        nameObject: insured.name,
        format: nameFormattingOptions.lastNameDisplayedFirst,
        defaultReturn: "-",
      });
      const ownersList = isDefined(owner)
        ? [
            {
              ...owner,
              name: {
                fullName: formatName({
                  nameObject: owner?.name,
                  format: compareStrings(searchBy, "trustOrOrganization")
                    ? nameFormattingOptions.firstNameDisplayedFirst
                    : nameFormattingOptions.lastNameDisplayedFirst,
                  defaultReturn: "-",
                }),
              },
            },
          ]
        : owners?.map((person) => {
            const {
              owner: { name = {}, ...ownerProps },
            } = person;
            const formattedName = formatName({
              nameObject: name,
              format: compareStrings(searchBy, "trustOrOrganization")
                ? nameFormattingOptions.firstNameDisplayedFirst
                : nameFormattingOptions.lastNameDisplayedFirst,
              defaultReturn: "-",
            });
            return { ...ownerProps, name: { fullName: formattedName } };
          });

      return {
        ...rest,
        agreementKeyAdmin: agreementSourceCode,
        policyId: policyNum,
        formattedLineOfBusinessCode: formatProductType(lineOfBusinessCode),
        productTypeCode,
        productTypeName: productTypeName || productTypeCode,
        lineOfBusinessCode,
        fullName: ownersList,
        insuredFullName: personInsuredFullName,
        birthDate: formatDate(insured.birthDate) || "-",
        governmentId: owners?.governmentId,
        status: formatStatusCode(statusCode),
        statusCode,
        formattedAgreementStartDate: formatDate(agreementStartDate) || "-",
      };
    }) || [];

  return sortByAscendingStartDate(formattedRows);
};

export const formatHomeOfficeAgreementSearchResults = (data) => {
  const formattedRows =
    data?.map((row) => {
      const personInsured = getUser({
        type: userTypes.INSURED,
        people: row.agreementCustomers,
      });

      const personInsuredFullName = formatName({
        nameObject: personInsured,
        format: nameFormattingOptions.lastNameDisplayedFirst,
      });

      const owner = getUser({
        type: userTypes.OWNER,
        people: row.agreementCustomers,
      });

      const policyOwners = removeOwnerAndInsuredDuplicates(
        getUsers(row.agreementCustomers, "OWNR")
      ).map((person) => {
        const fullName = formatName({
          nameObject: person,
          format: nameFormattingOptions.lastNameDisplayedFirst,
        });
        return { ...person, name: { fullName } };
      });

      return {
        ...row,
        fullName: policyOwners || "-",
        insuredFullName: personInsuredFullName || "-",
        birthDate: formatDate(personInsured.birthDate) || "-",
        governmentId: owner.governmentId,
        status: formatStatusCode(row.status),
        statusCode: row.status,
        formattedLineOfBusinessCode: formatProductType(row.lineOfBusinessCode),
        lineOfBusinessCode: row.lineOfBusinessCode,
        productTypeCode: row.productTypeCode,
        productTypeName: row.productTypeName,
      };
    }) || [];

  return sortByAscendingStartDate(formattedRows);
};

const sortByAscendingStartDate = (rows) =>
  rows.sort((a, b) => {
    const dateA = createDateObject(a.formattedAgreementStartDate);
    const dateB = createDateObject(b.formattedAgreementStartDate);

    const compareResult = compareDesc(dateA, dateB);

    if (isNaN(compareResult)) {
      return handleDateUndefinedSorting(
        a.formattedAgreementStartDate,
        b.formattedAgreementStartDate
      );
    }
    return compareResult;
  });

export const useSearchForPolicy = (props) => {
  const { user } = useUserState();
  const IS_HOME_OFFICE_USER = compareArrayOfStrings(
    user.groups,
    "fim-servicenet-homeoffice"
  );
  const {
    searchStringDefault = "",
    showClearIconDefault = false,
    searchByDefault = "lastName",
    disableSearchDefault = false,
    agentIdDefault = "",
    agencyNumberDefault = "",
    homeOfficeRadioSelctionDefault = "",
    setShowTable,
  } = props;

  const [searchBy, setSearchBy] = useState(searchByDefault);
  const [agencyNumber, setAgencyNumber] = useState(agencyNumberDefault);
  const [agentId, setAgentId] = useState(agentIdDefault);
  const [homeOfficeRadioSelection, setHomeOfficeRadioSelection] = useState(
    homeOfficeRadioSelctionDefault
  );
  const [disableSearch, setDisableSearch] = useState(disableSearchDefault);
  const [searchString, setSearchString] = useState(searchStringDefault);
  const [showClearIcon, setShowClearIcon] = useState(showClearIconDefault);

  useEffect(() => {
    const SHOW_CLEAR_ICON = searchString.length > 0;

    setShowClearIcon(SHOW_CLEAR_ICON);
  }, [searchString]);

  const requestBody = formatRequestBody({
    searchString,
    searchBy,
    agencyNumber,
    agentId,
    IS_HOME_OFFICE_USER,
    homeOfficeRadioSelection,
  });

  const IS_HOME_OFFICE_AND_SEARCHING_BY_POLICYNUM =
    IS_HOME_OFFICE_USER && compareStrings(searchBy, "policyNumberGlobal");

  const apiRequest = IS_HOME_OFFICE_AND_SEARCHING_BY_POLICYNUM
    ? "fetchDataByPolicyNumber"
    : "fetchAgentSearchResults";

  const apiParams = IS_HOME_OFFICE_AND_SEARCHING_BY_POLICYNUM
    ? { searchString }
    : { userToken: user.accessToken, userId: user.email, ...requestBody };

  const dataSelector = IS_HOME_OFFICE_AND_SEARCHING_BY_POLICYNUM
    ? "processHomeOfficeAgreementRequest"
    : "processAgentSearchResults";

  const agentSearchRequest = useMakeRequest({
    apiRequest,
    apiParams,
    immediateRequest: false,
    dataSelector,
    defaultReturn: [],
  });

  const { data, executeRequest, isLoading } = agentSearchRequest;
  const { policies, messages } = data;
  const MAX_RECORDS = compareStrings(
    messages?.[0].msgDesc,
    "SUCCESS - MAXRECORDS"
  );

  const formattedRows = useMemo(
    () =>
      IS_HOME_OFFICE_AND_SEARCHING_BY_POLICYNUM
        ? formatHomeOfficeAgreementSearchResults(data)
        : formatAgentSearchResults({ policies, searchBy }),
    [data]
  );
  const fetchPolicy = (event) => {
    event.preventDefault();
    const VALID_FORM = !disableSearch;
    if (VALID_FORM) {
      executeRequest();
      setShowTable(true);
    }
  };
  const INVALID_BPID_ERROR = compareStrings(
    "Invalid bpId",
    messages?.[0].msgDesc
  );

  return {
    agencyNumber,
    agentId,
    setAgencyNumber,
    setAgentId,
    searchString,
    showClearIcon,
    formattedRows: MAX_RECORDS ? [] : formattedRows,
    fetchPolicy,
    setSearchString,
    setSearchBy,
    isLoading,
    disableSearch,
    setDisableSearch,
    setHomeOfficeRadioSelection,
    agentSearchRequest,
    maxRecords: MAX_RECORDS,
    invalidBpidError: INVALID_BPID_ERROR,
  };
};

export const useSideBar = (props) => {
  const { selectedRowDefault = null } = props;
  const [selectedRow, setSelectedRow] = useState(selectedRowDefault);
  const closeDrawer = () => setSelectedRow(null);

  return { selectedRow, closeDrawer, setSelectedRow };
};

export const useFilteredTable = (props) => {
  const {
    sideBar = null,
    rows = [],
    filterOnDefault = false,
    maxRecords = false,
    invalidBpidError = false,
    filterStringDefault = "",
    showClearIconDefault = false,
    resultFiltering,
  } = props;
  const { user } = useUserState();
  const sessionData = getSessionStorageObject({ name: user.idToken });
  const defaultFilter =
    sessionData?.homePage?.filteredPoliciesOn || filterOnDefault;

  const [filterOn, setFilter] = useState(defaultFilter);
  const filterChanged = () => {
    setFilter((state) => !state);
    setSessionData(sessionData, user, filterOn);
  };

  const [filterString, setFilterString] = useState(filterStringDefault);
  const filterTable = ({ target: { value } }) => setFilterString(value);
  const clearFilter = () => setFilterString("");

  useEffect(() => {
    if (!resultFiltering) clearFilter();
  }, [resultFiltering]);

  const [showClearIcon, setShowClearIcon] = useState(showClearIconDefault);

  const customFunctions = { setSelectedRow: sideBar.setSelectedRow };
  let columns = [
    {
      accessor: "policyId",
      label: "Policy Number",
      alignment: "left",
      CustomCell: Links,
      linkTargetValue: "_blank",
      sortable: "String",
    },
    {
      accessor: "fullName",
      label: "Owner(s)",
      alignment: "left",
      CustomCell: OwnerButtonCell,
    },
    {
      accessor: "insuredFullName",
      label: "Primary Insured / Annuitant",
      alignment: "left",
      sortable: "String",
    },
    {
      accessor: "birthDate",
      label: "Date of Birth",
      alignment: "left",
      popover: {
        popoverId: "columnDOB_popover",
        text: "Date of Birth applies to the Primary Insured or the Annuitant",
      },
      sortable: "Date",
    },
    {
      accessor: "formattedLineOfBusinessCode",
      label: "Product Type",
      alignment: "left",
      sortable: "String",
    },
    {
      accessor: "productTypeName",
      label: "Product Name",
      alignment: "left",
      sortable: "String",
    },
    {
      accessor: "status",
      label: "Status",
      alignment: "left",
      sortable: "String",
    },
    {
      accessor: "quickLinks",
      label: "Actions",
      CustomCell: QuickLinks,
    },
  ];

  if (!resultFiltering) {
    const newColumns = [];
    columns.forEach((column) => {
      if (isDefined(column.sortable)) {
        delete column.sortable;
        newColumns.push(column);
      } else {
        newColumns.push(column);
      }
    });
    columns = newColumns;
  }

  const [displayedRows, setDisplayedRows] = useState(rows);
  const searchColumns = [
    "fullName",
    "insuredFullName",
    "policyId",
    "birthDate",
    "productTypeName",
    "formattedLineOfBusinessCode",
    "status",
  ];
  useDeepCompareEffect(() => {
    const filteredRows = filterRows({
      rows,
      filterOn,
    });
    const searchedRows = searchRows({
      searchString: filterString,
      initialRows: cloneDeep(filteredRows),
      columns,
      searchColumns,
    });
    setDisplayedRows(searchedRows);
    setShowClearIcon(filterString.length > 0);
  }, [rows, filterOn, filterString]);

  const maxRecordsText =
    "The search you tried to perform returned too many results; please adjust your search criteria.";

  const noResultsText = compareArrayOfStrings(
    user.groups,
    "fim-servicenet-homeoffice"
  )
    ? "We’re sorry! We can’t find the results that match your search. Please try again."
    : "Information is not available. Please contact our Service Center at 1-800-272-2216, 8:00 AM – 8:00 PM ET, Monday through Friday.";

  const invalidBpIdErrorMessage = compareArrayOfStrings(
    user.groups,
    "fim-servicenet-homeoffice"
  )
    ? "No agents were found with the ID you provided."
    : 'There was an error retrieving proxy information. Please call Enterprise Services at <a href="tel:+1-413-744-4357">(413)-744-4357</a> for assistance.';
  return {
    filterBar: {
      filterString,
      showClearIcon,
      filterTable,
      clearFilter,
    },
    columns,
    rows: displayedRows,
    customFunctions,
    filterOn,
    filterChanged,
    noResultsText: maxRecords ? maxRecordsText : noResultsText,
    invalidBpidMessage: invalidBpidError ? invalidBpIdErrorMessage : "",
    removeReloadButton: invalidBpidError,
  };
};

export const useFormFields = () => ({
  searchByType: {
    id: "selected",
    name: "searchByType",
    display: "Last Name",
    label: "Search By",
    labelBold: true,
    value: "lastName",
    errors: [],
    options,
  },
  searchByPolicyNumberGlobal: {
    id: "searchByPolicyNumberGlobal",
    label: "Search Criteria",
    labelBold: true,
    placeholder: "Enter Search Criteria",
    name: "searchByPolicyNumberGlobal",
    value: "",
    errors: [],
    postpone: true,
    formatting: formattingTypes.ALPHANUMERIC,
    useValidation: useGeneralValidation,
    min: 5,
    maxLength: 20,
  },
  searchByPolicyNumber: {
    id: "searchByPolicyNumber",
    label: "Search Criteria",
    labelBold: true,
    placeholder: "Enter Search Criteria",
    name: "searchByPolicyNumber",
    value: "",
    errors: [],
    postpone: true,
    formatting: formattingTypes.ALPHANUMERIC,
    useValidation: useGeneralValidation,
    min: 5,
    maxLength: 20,
  },
  searchByPhoneNumber: {
    id: "searchByPhoneNumber",
    label: "Search Criteria",
    labelBold: true,
    placeholder: "Enter Search Criteria",
    name: "searchByPhoneNumber",
    value: "",
    errors: [],
    postpone: true,
    formatting: formattingTypes.NUMBERS,
    useValidation: usePhoneSearchValidation,
    min: 10,
    maxLength: 16,
  },
  searchBySsnOrTin: {
    id: "searchBySsnOrTin",
    label: "Search Criteria",
    labelBold: true,
    placeholder: "Enter Search Criteria",
    name: "searchBySsnOrTin",
    value: "",
    errors: [],
    postpone: true,
    formatting: formattingTypes.NUMBERS,
    useValidation: useGeneralValidation,
    exactLength: 9,
    maxLength: 9,
  },
  searchByLastName: {
    id: "searchByLastName",
    label: "Search Criteria",
    labelBold: true,
    placeholder: "Enter Search Criteria",
    name: "searchByLastName",
    value: "",
    errors: [],
    postpone: true,
    formatting: formattingTypes.NAMES,
    useValidation: useGeneralValidation,
    min: 1,
  },
  firstNameField: {
    id: "firstNameField",
    label: "First Name",
    labelBold: true,
    name: "firstNameField",
    value: "",
    errors: [],
    postpone: true,
    formatting: formattingTypes.NAMES,
    useValidation: useGeneralValidation,
    min: 1,
  },
  lastNameField: {
    id: "lastNameField",
    label: "Last Name",
    labelBold: true,
    name: "lastNameField",
    value: "",
    errors: [],
    postpone: true,
    formatting: formattingTypes.NAMES,
    useValidation: useGeneralValidation,
    min: 1,
  },
  searchByFullName: {
    id: "searchByFullName",
    name: "searchByFullName",
    value: "",
    errors: [],
    postpone: true,
    formatting: formattingTypes.NAMES,
    useValidation: useGeneralValidation,
    min: 2,
  },
  searchByTrustOrOrganization: {
    id: "searchByTrustOrOrganization",
    label: "Search Criteria",
    labelBold: true,
    placeholder: "Enter Search Criteria",
    name: "searchByTrustOrOrganization",
    value: "",
    errors: [],
    postpone: true,
    useValidation: useGeneralValidation,
    min: 1,
  },
  searchByAgentId: {
    id: "searchByAgentId",
    name: "searchByAgentId",
    groupName: "agentIdOrAgencyNumber",
    label: "Agent ID",
    value: true,
  },
  searchByAgencyNumber: {
    id: "searchByAgencyNumber",
    name: "searchByAgencyNumber",
    groupName: "agentIdOrAgencyNumber",
    label: "Agency Number",
    value: false,
  },
  agentId: {
    id: "agentId",
    name: "agentId",
    value: "",
    errors: [],
    postpone: true,
    formatting: formattingTypes.NUMBERS,
    useValidation: useGeneralValidation,
    min: 5,
    max: 6,
    label: "Agent ID",
    hideLabel: true,
    prependText: "AA:",
    maxLength: 6,
    ariaLabel: "Agent ID",
  },
  agencyNumber: {
    id: "agencyNumber",
    placeholder: "",
    name: "agencyNumber",
    ariaLabel: "Agency Number",
    value: "",
    errors: [],
    postpone: true,
    formatting: formattingTypes.NUMBERS,
    useValidation: useGeneralValidation,
    max: 3,
    label: "Agency Number",
    hideLabel: true,
  },
  searchByGroupName: {
    id: "searchByGroupName",
    label: "Search Criteria",
    labelBold: true,
    placeholder: "Enter Search Criteria",
    name: "searchByGroupName",
    value: "",
    errors: [],
    postpone: true,
    useValidation: useGeneralValidation,
    min: 1,
  },
  searchByApmNumber: {
    id: "searchByApmNumber",
    label: "Search Criteria",
    labelBold: true,
    placeholder: "Enter Search Criteria",
    name: "searchByApmNumber",
    value: "",
    errors: [],
    postpone: true,
    formatting: formattingTypes.NUMBERS,
    useValidation: useGeneralValidation,
    min: 5,
  },
});

export const useCurrentSearchOption = () => {
  const {
    formData: {
      searchByType,
      searchByPolicyNumberGlobal,
      searchByPolicyNumber,
      searchByPhoneNumber,
      searchBySsnOrTin,
      searchByLastName,
      searchByFullName,
      searchByTrustOrOrganization,
      searchByGroupName,
      searchByApmNumber,
    },
  } = useFormContext();

  switch (searchByType.value) {
    case "policyNumberGlobal":
      return searchByPolicyNumberGlobal;
    case "policyNumber":
      return searchByPolicyNumber;
    case "phoneNumber":
      return searchByPhoneNumber;
    case "ssnOrTin":
      return searchBySsnOrTin;
    case "lastName":
      return searchByLastName;
    case "fullName":
      return searchByFullName;
    case "trustOrOrganization":
      return searchByTrustOrOrganization;
    case "groupName":
      return searchByGroupName;
    case "apmNumber":
      return searchByApmNumber;
    default:
      return searchByPolicyNumberGlobal;
  }
};

export const useGetAgentAgencyNumber = () => {
  const {
    formData: { searchByType, searchByAgentId, agentId, agencyNumber },
  } = useFormContext();

  if (searchByType.value !== "policyNumberGlobal") {
    if (searchByAgentId.value) {
      return agentId;
    }
    return agencyNumber;
  }

  return {};
};

export const useSearchFormProps = (props) => {
  const {
    search: {
      setAgencyNumber,
      setAgentId,
      fetchPolicy,
      setDisableSearch,
      setSearchString,
      setSearchBy,
      disableSearch,
      setHomeOfficeRadioSelection,
    },
  } = props;
  const {
    formData: {
      searchByType,
      lastNameField,
      firstNameField,
      searchByAgencyNumber,
      searchByAgentId,
      agentId,
      agencyNumber,
      searchByLastName,
      searchByPolicyNumberGlobal,
      searchByPolicyNumber,
      searchByPhoneNumber,
      searchBySsnOrTin,
      searchByTrustOrOrganization,
    },
    dispatchForm,
  } = useFormContext();

  const {
    user: { groups },
  } = useUserState();

  const isGlobalPolicySearchEnabled = useIsFeatureOn(
    "enableGlobalPolicySearch"
  );

  const showAgentAgencyForm =
    compareArrayOfStrings(groups, "fim-servicenet-homeoffice") &&
    searchByType.value !== "policyNumberGlobal";

  useDeepCompareEffect(() => {
    setSearchBy(searchByType.value);
  }, [searchByType, setSearchBy]);

  useEffect(() => {
    if (isGlobalPolicySearchEnabled) {
      dispatchForm({
        type: types.UPDATE_OPTIONS,
        formField: searchByType,
        options: [
          ...options,
          {
            id: "policy_number_option_global",
            label: "Policy Number - Global",
            value: "policyNumberGlobal",
          },
        ],
      });
    }
  }, [isGlobalPolicySearchEnabled]);

  const currentSelection = useCurrentSearchOption();
  const agentOrAgencyNumber = useGetAgentAgencyNumber();

  let currentHomeOfficeSelection = "agentId";
  if (searchByAgencyNumber.value) {
    currentHomeOfficeSelection = "agencyNumber";
  }
  if (searchByAgentId.value) {
    currentHomeOfficeSelection = "agentId";
  }

  useDeepCompareEffect(() => {
    const validateSearchCriteria =
      currentSelection.postpone || hasError(currentSelection);
    const validateAgentOrAgencyNumber =
      showAgentAgencyForm &&
      (agentOrAgencyNumber.postpone || hasError(agentOrAgencyNumber));

    const shouldDisable = validateSearchCriteria || validateAgentOrAgencyNumber;

    setDisableSearch(shouldDisable);
    setSearchString(currentSelection.value);
    setAgentId(agentId.value);
    setAgencyNumber(agencyNumber.value);
    setHomeOfficeRadioSelection(currentHomeOfficeSelection);
  }, [
    agentOrAgencyNumber,
    currentSelection,
    searchByType,
    setSearchString,
    agentId.value,
    agencyNumber.value,
    searchByAgencyNumber.value,
    searchByAgentId.value,
  ]);

  useDeepCompareEffect(() => {
    if (compareStrings(currentSelection.name, "searchByFullName")) {
      const BOTH_FIELDS_VALID =
        stringIsEmptyOrNullish(firstNameField.value) ||
        stringIsEmptyOrNullish(lastNameField.value);

      const fullName = {
        firstName: firstNameField.value.trim(),
        lastName: lastNameField.value.trim(),
      };

      dispatchForm({
        type: types.UPDATE_FIELD_VALUE,
        formField: currentSelection,
        newValue: fullName,
        postpone: BOTH_FIELDS_VALID,
      });
    }
  }, [
    firstNameField.value,
    lastNameField.value,
    dispatchForm,
    currentSelection,
  ]);

  const resetForm = () => {
    const fields = [
      searchByLastName,
      searchByPolicyNumberGlobal,
      searchByPolicyNumber,
      searchByPhoneNumber,
      searchBySsnOrTin,
      searchByTrustOrOrganization,
    ];
    fields.forEach((field) => {
      const { id } = field;
      if (!compareStrings(currentSelection.id, "searchByFullName")) {
        dispatchForm({
          type: types.UPDATE_FIELD_VALUE,
          formField: lastNameField,
          newValue: "",
          postpone: true,
        });
        dispatchForm({
          type: types.UPDATE_FIELD_VALUE,
          formField: firstNameField,
          newValue: "",
          postpone: true,
        });
      }
      if (!compareStrings(currentSelection.id, id)) {
        dispatchForm({
          type: types.UPDATE_FIELD_VALUE,
          formField: field,
          newValue: "",
          postpone: true,
        });
      }
    });
  };

  return {
    searchByType,
    disableSearch,
    resetForm,
    fetchPolicy,
    lastNameField,
    firstNameField,
    formField: currentSelection,
    agentId,
    agencyNumber,
    searchByAgencyNumber,
    searchByAgentId,
    showAgentAgencyForm,
  };
};

export const useGetHomeOfficeProps = (props) => {
  const currentDay = getCurrentDate();
  const [showTable, setShowTable] = useState(false);
  const { flagValues } = useGetFlagsValues(["restrictedPolicies"]);
  const restrictedPolicies = flagValues?.restrictedPolicies;
  const sideBar = useSideBar(props);
  const { selectedRow } = sideBar;
  const isRestrictedPolicy = compareArrayOfStrings(
    restrictedPolicies,
    selectedRow?.policyId
  );

  const resultFiltering = useLDValue({
    flagName: ["sortFilterLandingPage"],
  });

  const search = useSearchForPolicy({
    setShowTable,
  });
  const table = useFilteredTable({
    resultFiltering,
    sideBar,
    rows: search.formattedRows,
    maxRecords: search.maxRecords,
    invalidBpidError: search.invalidBpidError,
    removeReloadButton: search.removeReloadButton,
    filterStringDefault: "",
  });
  const formFields = useFormFields();
  const {
    user: { email },
  } = useUserState();
  return {
    data: { currentDay },
    search,
    sideBar,
    table,
    showTable,
    formFields,
    email,
    isRestrictedPolicy,
    resultFiltering,
  };
};

const options = [
  { id: "last_name_option", label: "Last Name", value: "lastName" },
  {
    id: "policy_number_option",
    label: "Policy Number",
    value: "policyNumber",
  },
  { id: "govt_id_option", label: "SSN / TIN", value: "ssnOrTin" },
  {
    id: "phone_number_option",
    label: "Phone Number",
    value: "phoneNumber",
  },
  { id: "full_name_option", label: "Full Name", value: "fullName" },
  {
    id: "trust_or_organization_option",
    label: "Trust / Organization",
    value: "trustOrOrganization",
  },
  {
    id: "group_name_option",
    label: "Group Name",
    value: "groupName",
  },
  {
    id: "apm_number_option",
    label: "APM Number",
    value: "apmNumber",
  },
];
