/* eslint-disable no-plusplus */
import { useParams } from "react-router-dom";
import { nameCase } from "@foundernest/namecase";
import { useState } from "react";
import { getCurrentDate, formatDate } from "../../../../utils/dates";
import { isUnauthorizedPolicy } from "../../../../utils/errorChecking";
import {
  formatName,
  nameFormattingOptions,
  cutPrecedingZerosFromId,
  formatMoney,
} from "../../../../utils/format";
import { useMakeRequest } from "../../../../hooks/api";
import CashValueCell from "./customCells/CashValueCell";
import DateCell from "./customCells/DateCell";
import {
  getFirstServicingAgent,
  getLoggedInUserInfo,
  getTrimmedPolicyIdFromAgreement,
  getUserBySubType,
  userTypes,
} from "../../../../utils/findData";
import { isEnvisionPolicy } from "../../../../utils/policies";
import { useUserState } from "../../../../contexts/user";
import { compareStrings } from "../../../../utils/string";
import { AGREEMENT_NOT_FOUND } from "../../../../constants/ui";
import Download from "../../../common/table/customCells/download";
import {
  isProducersDataEligibleForCashValueDownload,
  useVerifyCashValueQuoteLink,
} from "./restrictions";
import {
  contentApiPdfRequestNumericalFields,
  setUndefinedFieldsTo0,
} from "../../../../utils/setFieldValues";
import { fetchPdfFromContentApi } from "../../../../api";
import {
  convertBase64StringToByteArray,
  downloadPDF,
} from "../../../common/helpers/downloadHelper";
import { supportedTransactions } from "../../../../utils/translate";
import { fireTealiumEvent } from "../../../../utils/tealium";

export const columns = [
  {
    accessor: "date",
    label: "Date",
    alignment: "left",
    CustomCell: DateCell,
    dataCell: true,
  },
  {
    accessor: "valuation",
    label: "Net Cash Value",
    alignment: "left",
    CustomCell: CashValueCell,
  },
];

export const getRows = (
  agreementData,
  producersData,
  user,
  agreementKey,
  setShowLoadingIcon
) => {
  let month = 0;
  const cashValueTableData = [];
  const latestMonthTableData = [];
  const { asOfDate, netSurrenderAmount } = agreementData;

  while (month <= 12 && agreementData) {
    if (month === 0) {
      latestMonthTableData.push({
        id: `${month}`,
        date: formatDate(asOfDate, "monthCommaDayYear"),
        valuation: formatMoney(netSurrenderAmount, "Not Available"),
        downloadType: "PDF",
        agreementData,
        producersData,
        user,
        agreementKey,
        setShowLoadingIcon,
      });
    } else if (agreementData[`valuationMonth${month}Date`]) {
      cashValueTableData.push({
        id: `${month}`,
        date: formatDate(
          agreementData[`valuationMonth${month}Date`],
          "monthCommaDayYear"
        ),
        valuation:
          agreementData[`netSurrenderValueMonth${month}Amount`].toFixed(2),
        downloadType: "PDF",
        agreementData,
        producersData,
        user,
        agreementKey,
        setShowLoadingIcon,
      });
    }
    month++;
  }

  cashValueTableData.sort((val1, val2) => {
    const date1 = new Date(val1.date);
    const date2 = new Date(val2.date);
    return date2 - date1;
  });

  return { cashValueTableData, latestMonthTableData };
};

export const getLatestMonthTableColumns = (data) => {
  const { commonColumns, isEligibleForQuoteDownload, showLoadingIcon } = data;

  const latestMonthTableColumns = [...commonColumns];
  if (isEligibleForQuoteDownload) {
    latestMonthTableColumns.push({
      accessor: "downloadType",
      label: "Document",
      alignment: "left",
      CustomCell: Download,
      showIcon: true,
      showLoadingIcon,
      isCalledFrom: supportedTransactions.CashValueQuote,
    });
  }

  return latestMonthTableColumns;
};

export const generateAndDownloadPDF = (row) => {
  const {
    agreementData,
    producersData,
    user,
    agreementKey,
    setShowLoadingIcon,
  } = row;

  const reqBody = makeCashValueQuoteDownloadRequestBody({
    agreementData,
    producersData,
    user,
  });
  const headers = {
    Authorization: `Bearer ${user.accessToken}`,
  };
  const docType = "cashvalue";
  const downloadFilename = `${formatDate(
    getCurrentDate().toString(),
    "YYYY-MM-DD",
    ""
  )}-CashValue.pdf`;
  const { trimmedPolicyId } = getTrimmedPolicyIdFromAgreement(agreementKey);
  setShowLoadingIcon(true);

  fetchPdfFromContentApi({ headers, apiParams: { reqBody, docType } })
    .then((result) => {
      if (result?.data?.length && !result?.error) {
        downloadPDF(
          "",
          "",
          convertBase64StringToByteArray(result?.data),
          downloadFilename
        );
        fireTealiumEvent({
          label: "SVNT",
          action: `${supportedTransactions.CashValueQuote} Download Completed`,
          category: supportedTransactions.CashValueQuote,
          value: trimmedPolicyId,
          custId: user?.email,
        });
        setShowLoadingIcon(false);
      }
    })
    .catch(() => {
      setShowLoadingIcon(false);
      fireTealiumEvent({
        label: "SVNT",
        action: `${supportedTransactions.CashValueQuote} Download Failed`,
        category: supportedTransactions.CashValueQuote,
        value: trimmedPolicyId,
        custId: user?.email,
      });
    });
};

export const makeCashValueQuoteDownloadRequestBody = (props) => {
  const { agreementData, producersData, user } = props;
  const { unprocessedAgreementData } = agreementData;

  const servicingAgent = getFirstServicingAgent({
    agents: producersData?.producers,
  });

  const {
    firstName = "",
    lastName = "",
    fullName = "",
    businessPartnerId = "",
    homeAgencyId = "",
  } = servicingAgent;

  const formattedFullName = formatName({
    nameObject: {
      fullName,
    },
    format: "firstNameDisplayedFirst",
  });

  const formattedRequestingAgency =
    cutPrecedingZerosFromId(homeAgencyId).toString();

  const producerInfo = [
    {
      firstName: nameCase(firstName),
      lastName: nameCase(lastName),
      businessPartnerId: businessPartnerId.toString(),
      requestorTypeCode: "A",
      fullName: formattedFullName,
      requestingAgency: formattedRequestingAgency,
    },
  ];

  const loggedInUserInfo = getLoggedInUserInfo(user);

  const currentDate = formatDate(getCurrentDate().toString(), "YYYY-MM-DD", "");
  const processedAgreementData = setUndefinedFieldsTo0({
    fields: contentApiPdfRequestNumericalFields,
    data: unprocessedAgreementData.agreements[0],
  });

  const requestBody = {
    agreements: [
      {
        ...processedAgreementData,
        producers: producerInfo,
        loggedInUser: loggedInUserInfo,
        currentDate,
      },
    ],
    messages: unprocessedAgreementData?.messages,
    transType: unprocessedAgreementData?.transType,
    transGuid: unprocessedAgreementData?.transGuid,
    transExecDateTS: unprocessedAgreementData?.transExecDateTS,
  };

  return requestBody;
};
export const useCashValuesProps = () => {
  const { agreementKey } = useParams();
  const [showLoadingIcon, setShowLoadingIcon] = useState(false);

  const producersRequest = useMakeRequest({
    apiRequest: "fetchProducersData",
    apiParams: { agreementKey },
    immediateRequest: false,
    dataSelector: "processProducersData",
  });
  const agreementRequest = useMakeRequest({
    apiRequest: "fetchAgreementData",
    apiParams: { agreementKey },
    immediateRequest: true,
    dataSelector: "processAgreementRequest",
    defaultReturn: { agreements: [{}] },
    dependentRequests: [producersRequest],
  });
  const { description, code, type } = agreementRequest.data.messages?.[0] || {};
  const agreementData = agreementRequest.data;
  const producersData = producersRequest?.data;
  const { agreementCustomers, policyId, isUnauthorized, isPolicyNotFound } =
    agreementData;

  const personInsured = getUserBySubType({
    type: userTypes.INSURED,
    subType: "PRMR",
    people: agreementCustomers,
  });
  const insuredName = formatName({
    nameObject: personInsured,
    format: nameFormattingOptions.firstNameDisplayedFirst,
  });
  const { user } = useUserState();
  const currentDate = getCurrentDate();
  const { cashValueTableData, latestMonthTableData } = getRows(
    agreementData,
    producersData,
    user,
    agreementKey,
    setShowLoadingIcon
  );

  const { isEligibleForCashValueQuoteLink } =
    useVerifyCashValueQuoteLink(agreementRequest);

  const { isProducerEligibleForCashValueDownload } =
    isProducersDataEligibleForCashValueDownload(producersData);

  const isEligibleForQuoteDownload =
    isEligibleForCashValueQuoteLink && isProducerEligibleForCashValueDownload;

  const historyTableColumns = [...columns];
  if (isEligibleForQuoteDownload) {
    historyTableColumns.push({
      alignment: "left",
      showIcon: false,
    });
  }
  const table = {
    columns: historyTableColumns,
    cashValueTableData,
    latestMonthTableColumns: getLatestMonthTableColumns({
      commonColumns: columns,
      isEligibleForQuoteDownload,
      showLoadingIcon,
    }),
    latestMonthTableData,
  };

  const ACCESS_DENIED = isUnauthorizedPolicy(description, code);
  const ENVISION_POLICY = isEnvisionPolicy(agreementRequest.data);
  const latestMonthTableCustomFunctions = {
    generateAndDownloadPDF,
  };

  const validRequest =
    !isUnauthorized &&
    compareStrings(type, "SUCCESS") &&
    !compareStrings(description, AGREEMENT_NOT_FOUND);

  return {
    ACCESS_DENIED,
    ENVISION_POLICY,
    requests: { agreementRequest, producersRequest },
    insuredName,
    currentDate,
    policyId,
    table,
    email: user?.email,
    validRequest,
    latestMonthTableCustomFunctions,
    showLoadingIcon,
    setShowLoadingIcon,
    isPolicyNotFound,
  };
};
