import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import { types, useFormContext } from "../../contexts/form";
import { useMakeRequest } from "../../hooks/api";
import {
  useEmailValidation,
  hasError,
  useConfirmEmailValidation,
} from "../../utils/validation";
import militaryStates from "../../data/militaryStates.json";
import citiesAndPostOffice from "../../data/citiesAndPostOffice.json";
import { isArray, isDefined, isEmptyObject } from "../../utils/evaluate";
import { formatDate, getCurrentDate } from "../../utils/dates";
import { useUserState } from "../../contexts/user";
import {
  addComma,
  compareArrayOfStrings,
  compareStrings,
  containsArrayOfStrings,
  trimStart,
} from "../../utils/string";
import { formatAddress, formatName, getLastXChars } from "../../utils/format";
import {
  getPeople,
  sortPeopleByRoles,
} from "../policyDetails/sections/peopleOnPolicy/hooks";
import {
  getFirstServicingAgent,
  getTrimmedPolicyIdFromAgreement,
  getBpIdFromUser,
  isHomeOfficeUser,
  getLabel,
} from "../../utils/findData";
import {
  getVerifyPolicyAgreementInfo,
  checkIfTransactionIsAllowed,
  isEnvisionPolicy,
  isRetireEaseContract,
  isFoundationFlexiblePremium,
  isFoundationSinglePremium,
  isFlexIVFund1FPOrFund4FP,
} from "../../utils/policies";
import "moment-timezone";
import { useIsFeatureOn, useLDValue } from "../common/featureFlags/hooks";
import { fireTealiumEvent } from "../../utils/tealium";
import { useRequestorContactEmail } from "../../hooks/helper";
import { checkForRequestorContactError } from "../common/requestorContact/hooks";
import {
  getRoleName,
  roleNames,
  supportedTransactions,
  userTypes,
} from "../../utils/translate";
import {
  isAgreementDataEligibleforChangeAddress,
  isBillingDataEligibleForChangeAddress,
} from "./restrictions";
import {
  AGREEMENT_NOT_FOUND,
  AUTHORIZATION_CONFIRMATION_STATEMENT,
} from "../../constants/ui";
import { updateCustomersAddress } from "../../api";
import { ADDRESS_CHANGE_MAX_RETRY_COUNT } from "../../constants/logic";
import {
  checkForRequiredFieldErrors,
  useEditAddressFields,
  useAddressVerificationModal,
  useUpdateEditAddressVariables,
} from "../common/editAddress/hooks";

/**
 *
 * * Page Hooks
 *
 * */

export const useChangeAddressProps = () => {
  const { agreementKey } = useParams();
  const transactionSupportedPolicies = useLDValue({
    flagName: ["transactionSupportedPolicies"],
  });

  const validRequest = (dependentAgreementData) => {
    const { isUnauthorized } = dependentAgreementData;
    const { type, description } = dependentAgreementData?.messages || {};
    return (
      !isUnauthorized &&
      compareStrings(type, "SUCCESS") &&
      !compareStrings(description, AGREEMENT_NOT_FOUND) &&
      isAgreementDataEligibleforChangeAddress({
        agreementData: dependentAgreementData,
        transactionSupportedPolicies,
      })
    );
  };

  const billingRequest = useMakeRequest({
    apiRequest: "fetchBillingInformation",
    apiParams: { agreementKey, showExtended: false },
    dataSelector: "processBillingInformation",
    immediateRequest: false,
    updateRequestCallback: (dependentAgreementData) => {
      const { issueDate, jurisdictionState } = dependentAgreementData;
      const dataForSelectors = isDefined(issueDate)
        ? { jurisdictionState }
        : { issueDate, jurisdictionState };
      return { dataForSelectors };
    },
    canExecuteCallBack: validRequest,
  });

  const producersRequest = useMakeRequest({
    apiRequest: "fetchProducersData",
    apiParams: { agreementKey },
    immediateRequest: false,
    dataSelector: "processProducersData",
  });

  const agreementRequest = useMakeRequest({
    apiRequest: "fetchAgreementData",
    apiParams: { agreementKey },
    immediateRequest: true,
    dataSelector: "processAgreementRequest",
    dependentRequests: [billingRequest, producersRequest],
  });

  const contractNbr = agreementRequest?.data?.policyId;
  const isAgreementLoaded =
    !agreementRequest.isLoading && isDefined(agreementRequest?.data?.policyId);

  const agreementData = agreementRequest.data;
  const { lineOfBusinessCode, kindCode, isPolicyNotFound } =
    agreementRequest.data;

  const shouldUseSoaService =
    isRetireEaseContract(agreementData) ||
    isFoundationFlexiblePremium(agreementData) ||
    isFoundationSinglePremium(agreementData) ||
    isFlexIVFund1FPOrFund4FP(kindCode);

  const annuityRequest = useMakeRequest({
    apiRequest: shouldUseSoaService
      ? "fetchAnnuityByAgreementKey"
      : "fetchAnnuityByContractNbr",
    apiParams: { contractNbr, agreementKey },
    dataSelector: "processAnnuityData",
    immediateRequest: false,
    initiateDevModeDispatch: false,
  });

  const enableEnvisionFlow = useIsFeatureOn("enableEnvisionFlow");

  const billingData = billingRequest.data;

  const isEnvisionContract = isEnvisionPolicy(agreementData);
  const isLifeOrDiProduct =
    compareStrings(lineOfBusinessCode, "LIFE") ||
    compareStrings(lineOfBusinessCode, "DI");

  const [showErrorPage, setShowErrorPage] = useState(false);
  const [isSubmissionInProgress, setIsSubmissionInProgress] = useState(false);
  const [submissionRetry, setSubmissionRetry] = useState({
    retryAttemptCount: 0,
    retryList: [],
  });

  useEffect(() => {
    if (isAgreementLoaded) {
      if (!isEnvisionContract && !isLifeOrDiProduct) {
        annuityRequest.executeRequest();
      }
    }
  }, [isAgreementLoaded]);

  const annuityData = annuityRequest?.data;

  const [transactionId, setTransactionId] = useState("");
  const [errorText, setErrorText] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [error, setError] = useState(null);
  const { user } = useUserState();

  const { isMmsdOnly } = getVerifyPolicyAgreementInfo({
    enableEnvisionFlow,
    agreementRequest,
    user,
  });

  const nextPage = () => setPageNumber(pageNumber + 1);
  const lastPage = () => setPageNumber(pageNumber - 1);
  const previousPage = () => {
    lastPage();
    setError(null);
  };

  const { email } = user;
  const shouldDisableSubmit = isHomeOfficeUser(user);
  const { trimmedPolicyId } = getTrimmedPolicyIdFromAgreement(agreementKey);

  const producerData = producersRequest?.data;
  const servicingAgent = getFirstServicingAgent({
    agents: producerData?.producers,
  });

  const onSubmit = (formData, listOfMemberGuidForRetry) => {
    setIsSubmissionInProgress(true);
    const headers = {
      Authorization: `Bearer ${user.accessToken}`,
    };
    const users = [];
    for (let i = 0; i < formData.totalCustomers; i += 1) {
      users.push(formData[`customer${i}`]);
    }

    const selectedUsers = getSelectedUsers(users);

    const requestBody = makeRequestBody({
      user,
      servicingAgent,
      formData,
      selectedUsers,
      listOfMemberGuidForRetry,
    });

    updateCustomersAddress({ headers, apiParams: requestBody })
      .then((result) => {
        // Http status 207 when it is partial sucess. Address update failed for somecustomer not all.
        // Code will automatically retry till the max retry count.
        if (result?.status === 207) {
          const { retryAttemptCount } = submissionRetry;
          const addressUpdateErrors = result?.data?.errors || [];
          const retryList = addressUpdateErrors.map(
            (addressUpdateError) => addressUpdateError.memberGUID
          );
          setSubmissionRetry({
            retryAttemptCount: retryAttemptCount + 1,
            retryList,
          });
        } else {
          setIsSubmissionInProgress(false);
          fireTealiumEvent({
            label: "SVNT",
            action: `Change Address Submitted`,
            category: "Change Address",
            value: trimmedPolicyId,
            custId: email,
          });
          setPageNumber(5);
        }
      })
      .catch((e) => {
        const { retryAttemptCount } = submissionRetry;
        const submissionErrors = e.response?.data?.errors || [];
        const transactionIds = [];
        const retryList = [];
        if (isArray(submissionErrors) && submissionErrors.length > 0) {
          submissionErrors.forEach((submissionError) => {
            if (submissionError) {
              transactionIds.push(submissionError.transGuid);
              retryList.push(submissionError.memberGUID);
            }
          });
        }

        if (
          retryAttemptCount > 0 &&
          retryAttemptCount < ADDRESS_CHANGE_MAX_RETRY_COUNT &&
          retryList.length > 0
        ) {
          // retry till the max retry count
          setSubmissionRetry({
            retryAttemptCount: retryAttemptCount + 1,
            retryList,
          });
        } else {
          // This block is for compltete failure on first attempt or after reaching max retry count.
          setIsSubmissionInProgress(false);
          setTransactionId(transactionIds.join(","));

          let submissionErrorText =
            "There was an error processing your request; please try again later.";
          if (retryAttemptCount > 0) {
            submissionErrorText =
              "There was an error processing your request. Please contact our Service Center at 1-800-272-2216, Mon - Fri 8 a.m. - 6 p.m. ET";
          }
          if (compareStrings("Network Error", e.message)) {
            submissionErrorText =
              "The page you are looking for is temporarily unavailable.";
          }
          setErrorText(submissionErrorText);
          fireTealiumEvent({
            label: "SVNT",
            action: `${lineOfBusinessCode} Change Address Failed`,
            category: "Change Address",
            value: trimmedPolicyId,
            custId: email,
          });
          setShowErrorPage(true);
        }
      });
  };
  const fields = useChangeAddressFields({ agreementData });
  const changeAddressVerification = verifyChangeAddress({
    data: agreementData,
    policyId: trimmedPolicyId,
    prependPolicyDetails: true,
    transactionSupportedPolicies,
    billingData,
    annuityData,
  });

  const isPolicyLoaded =
    isDefined(trimmedPolicyId) && isDefined(lineOfBusinessCode);

  const policyNotSupported =
    !changeAddressVerification.isTransactionAllowed && isPolicyLoaded;

  const changeAddressIsUnavailable =
    changeAddressVerification.unavailable || policyNotSupported;

  return {
    pageNumber,
    showErrorPage,
    nextPage,
    previousPage,
    agreementData,
    fields,
    agreementRequest,
    producersRequest,
    error,
    setError,
    setPageNumber,
    onSubmit,
    submissionRetry,
    transactionId,
    errorText,
    shouldDisableSubmit,
    email: user?.email,
    changeAddressIsUnavailable,
    isMmsdOnly,
    changeAddressTransactionAllowed:
      changeAddressVerification.isTransactionAllowed,
    policyId: trimmedPolicyId,
    isPolicyLoaded,
    isSubmissionInProgress,
    annuityRequest,
    isPolicyNotFound,
  };
};

export const useSelectUserProps = (props) => {
  const {
    agreementData: {
      agreementKeyAdmin,
      policyId,
      minorProductName,
      agreementKeyPrimary,
    },
  } = props;

  const { formData } = useFormContext();
  const { authorizedToChange, notmyAddress, totalCustomers } = formData;

  const users = [];
  for (let i = 0; i < totalCustomers; i += 1) {
    users.push(formData[`customer${i}`]);
  }

  return {
    policyId,
    minorProductName,
    users,
    authorizedToChange,
    notmyAddress,
    agreementKeyAdmin,
    agreementKeyPrimary,
  };
};

export const useRequestorContactProps = () => {
  useUpdateEditAddressVariables();
};

export const useReviewProps = () => {
  const { formData } = useFormContext();
  const {
    user: { firstName, lastName },
  } = useUserState();
  const {
    totalCustomers,
    editAddressAddress1,
    editAddressAddress2,
    editAddressAddress3,
    editAddressCity,
    editAddressState,
    editAddressZipCode,
    editAddressCountry,
    editAddressIsMilitaryAddress,
    editAddressMilitaryCity,
    editAddressMilitaryState,
    editAddressMilitaryZipCode,
  } = formData;
  const users = [];
  for (let i = 0; i < totalCustomers; i += 1) {
    users.push(formData[`customer${i}`]);
  }

  const selectedUsers = getSelectedUsers(users);
  const preferredMethodOfContact = getPreferredMethodOfContact(formData);

  return {
    selectedUsers,
    preferredMethodOfContact,
    address1: editAddressAddress1.value,
    address2: editAddressAddress2.value,
    address3: editAddressAddress3.value,
    city: editAddressIsMilitaryAddress.value
      ? getLabel(citiesAndPostOffice, editAddressMilitaryCity.value)
      : editAddressCity.value,
    state: editAddressIsMilitaryAddress.value
      ? getLabel(militaryStates, editAddressMilitaryState.value)
      : editAddressState.value,
    zipCode: editAddressIsMilitaryAddress.value
      ? editAddressMilitaryZipCode.value
      : editAddressZipCode.value,
    countryCode: editAddressCountry.code,
    requestorName: `${firstName} ${lastName}`,
    displayInsuredRole,
  };
};

export const useConfirmationProps = () => {
  const { formData } = useFormContext();
  const { agreementKey } = useParams();
  const {
    totalCustomers,
    editAddressAddress1,
    editAddressAddress2,
    editAddressAddress3,
    editAddressCity,
    editAddressState,
    editAddressZipCode,
    editAddressCountry,
    editAddressIsMilitaryAddress,
    editAddressMilitaryCity,
    editAddressMilitaryState,
    editAddressMilitaryZipCode,
  } = formData;
  const {
    user: { firstName, lastName, email },
  } = useUserState();
  const users = [];
  for (let i = 0; i < totalCustomers; i += 1) {
    users.push(formData[`customer${i}`]);
  }
  const IS_MILTARY_ADDRESS = editAddressIsMilitaryAddress.value;
  let newAddress = "";
  if (IS_MILTARY_ADDRESS) {
    newAddress = `${editAddressAddress1.value}, ${addComma(
      editAddressAddress2.value
    )} ${addComma(editAddressAddress3.value)} ${getLabel(
      citiesAndPostOffice,
      editAddressMilitaryCity.value
    )}, ${getLabel(militaryStates, editAddressMilitaryState.value)}, ${
      editAddressMilitaryZipCode.value
    }, ${editAddressCountry.code}`;
  } else {
    newAddress = `${editAddressAddress1.value}, ${addComma(
      editAddressAddress2.value
    )} ${addComma(editAddressAddress3.value)} ${editAddressCity.value}, ${
      editAddressState.value
    }, ${editAddressZipCode.value}, ${editAddressCountry.code}`;
  }

  const selectedUsers = getSelectedUsers(users);
  const preferredMethodOfContact = getPreferredMethodOfContact(formData);

  const requestDateTime = formatDate(getCurrentDate(), "dateWithTime");
  const { trimmedPolicyId } = getTrimmedPolicyIdFromAgreement(agreementKey);
  return {
    selectedUsers,
    preferredMethodOfContact,
    requestorName: `${firstName} ${lastName}`,
    newAddress,
    requestDateTime,
    agreementKey,
    displayInsuredRole,
    userEmail: email,
    trimmedPolicyId,
  };
};

/**
 *
 * * Modal Functions
 *
 * */

export const useCanContinue = (props) => {
  const [isIntialPage1Render, setIsIntialPage1Render] = useState(false);
  const { formData } = useFormContext();
  const { pageNumber, nextPage, error, setError } = props;
  const {
    authorizedToChange,
    notmyAddress,
    editAddressCountry,
    editAddressAddress1,
    editAddressAddress2,
    editAddressAddress3,
    editAddressCity,
    editAddressState,
    editAddressZipCode,
    editAddressIsMilitaryAddress,
    editAddressMilitaryCity,
    editAddressMilitaryState,
    editAddressMilitaryZipCode,
  } = formData;
  const { agreementKey } = useParams();
  const { trimmedPolicyId } = getTrimmedPolicyIdFromAgreement(agreementKey);
  const {
    user: { email },
  } = useUserState();
  const { openAddressVerification } = useAddressVerificationModal({
    nextPage,
    setError,
  });
  const requestorContactEmail = useRequestorContactEmail();

  if (isIntialPage1Render && pageNumber === 1) {
    checkForErrorsPage1({
      authorizedToChange,
      notmyAddress,
      setError,
      formFields: formData,
      nextPage,
      updateStateVariables: false,
    });
  }

  const onContinue = () => {
    if (pageNumber === 1) {
      setIsIntialPage1Render(true);
      checkForErrorsPage1({
        authorizedToChange,
        notmyAddress,
        setError,
        formFields: formData,
        nextPage,
        updateStateVariables: true,
      });
    } else if (pageNumber === 2) {
      checkForErrorsPage2({
        editAddressCountry,
        editAddressAddress1,
        editAddressAddress2,
        editAddressAddress3,
        editAddressCity,
        editAddressState,
        editAddressZipCode,
        setError,
        editAddressIsMilitaryAddress,
        editAddressMilitaryCity,
        editAddressMilitaryState,
        editAddressMilitaryZipCode,
        openAddressVerification,
        nextPage,
        updateStateVariables: true,
      });
    } else if (pageNumber === 3) {
      const { hasRequestorContactError } = checkForRequestorContactError({
        formData,
        setError,
        requestorContactEmail,
      });
      if (!hasRequestorContactError) {
        nextPage();
      }
    } else {
      nextPage();
    }
    fireTealiumEvent({
      label: "SVNT",
      action: `Change Address PageNumber ${pageNumber} continue button clicked`,
      category: "Change Address",
      value: trimmedPolicyId,
      custId: email,
    });
  };

  return { onContinue, error };
};

export const isAddressEditable = (customer) => {
  let ownerOrPpayIsMassMutual = false;
  if (
    compareArrayOfStrings(customer.customer.arrayRoleType, "PPAY") ||
    compareArrayOfStrings(customer.customer.arrayRoleType, "OWNR")
  ) {
    if (
      customer.customer.fullName.toLowerCase().match(/massmutual/) ||
      customer.customer.fullName.toLowerCase().match(/mass mutual/)
    ) {
      ownerOrPpayIsMassMutual = true;
    }
  }

  return !ownerOrPpayIsMassMutual;
};

export const getSelectedUsers = (users = []) => {
  const filteredUsers = users.filter((user) => user.value);
  return filteredUsers.map((user) => ({
    ...user,
    display: user.label,
  }));
};

export const getPreferredMethodOfContact = (formData) => {
  const { contactChoiceEmailInput } = formData;

  return contactChoiceEmailInput.value || "";
};

export const displayInsuredRole = (roles, agreementKeyAdmin) => {
  const rolesArr = roles.split(",");
  const displayString = rolesArr.filter(
    (role) =>
      !(
        compareStrings("Insured", role.trim()) &&
        !compareArrayOfStrings(["PE1", "VNTG1"], agreementKeyAdmin)
      )
  );
  return displayString.join(",");
};

export const handleConfirmationPrintButtonClick = (policyId, userEmail) => {
  fireTealiumEvent({
    label: "SVNT",
    action: `Change Address Confirmation Download Button click`,
    category: "Change Address",
    value: policyId,
    custId: userEmail,
  });
  window.print();
};

export const checkForErrorsPage1 = (props) => {
  const {
    authorizedToChange,
    notmyAddress,
    setError,
    formFields,
    nextPage,
    updateStateVariables,
  } = props;
  let atLeastOneUserSelected = false;
  let showNoPoliciesError = false;
  let showApiError = false;

  for (let i = 0; i < formFields.totalCustomers; i += 1) {
    const customerIsSelected = formFields[`customer${i}`].value;
    const hasApiError = formFields[`customer${i}`].apiError;
    const hasPolicies = formFields[`customer${i}`].policies?.length > 0;
    if (!atLeastOneUserSelected) {
      if (customerIsSelected) {
        atLeastOneUserSelected =
          customerIsSelected && hasPolicies && !hasApiError;
      }
    }
    if (customerIsSelected) {
      if (!showNoPoliciesError) {
        showNoPoliciesError = !hasPolicies && !hasApiError;
      }
      if (!showApiError) {
        showApiError = hasApiError;
      }
    }
  }
  const bothChecked = authorizedToChange.value && notmyAddress.value;
  if (
    bothChecked &&
    atLeastOneUserSelected &&
    !showApiError &&
    !showNoPoliciesError
  ) {
    if (updateStateVariables) {
      nextPage();
    }
    setError(null);
  } else {
    const areBothChecked = authorizedToChange.value && notmyAddress.value;
    const authorizedToChangeNotChecked = !authorizedToChange.value;
    const notMyAddressNotChecked = !notmyAddress.value;
    if (updateStateVariables) {
      if (!atLeastOneUserSelected) {
        if (!areBothChecked) {
          if (showApiError && !showNoPoliciesError) {
            setError([
              "Information for the client(s) you’ve selected is not available to complete an address change at this time. Please deselect in order to continue.",
              <br />,
              <br />,
              "If you cannot acknowledge these statements, you may contact our service center for more information at 1-800-272-2216 Mon-Fri 8am-8pm ET. If you can acknowledge these statements, select the check boxes and press continue to proceed.",
            ]);
          } else if (showNoPoliciesError && !showApiError) {
            setError([
              "Change of address is not applicable for the client you have selected. Please deselect checkbox to continue with the transaction.",
              <br />,
              <br />,
              "If you cannot acknowledge these statements, you may contact our service center for more information at 1-800-272-2216 Mon-Fri 8am-8pm ET. If you can acknowledge these statements, select the check boxes and press continue to proceed.",
            ]);
          } else if (showNoPoliciesError && showApiError) {
            setError([
              "Change of address is not applicable for the client you have selected. Please deselect checkbox to continue with the transaction.",
              <br />,
              <br />,
              "Information for the client(s) you’ve selected is not available to complete an address change at this time. Please deselect in order to continue.",
              <br />,
              <br />,
              "If you cannot acknowledge these statements, you may contact our service center for more information at 1-800-272-2216 Mon-Fri 8am-8pm ET. If you can acknowledge these statements, select the check boxes and press continue to proceed.",
            ]);
          } else {
            setError([
              "Please select the customer(s) for whom this address change should apply.",
              <br />,
              <br />,
              "If you cannot acknowledge these statements, you may contact our service center for more information at 1-800-272-2216 Mon-Fri 8am-8pm ET. If you can acknowledge these statements, select the check boxes and press continue to proceed.",
            ]);
          }
        } else if (showApiError && !showNoPoliciesError) {
          setError(
            "Information for the client(s) you’ve selected is not available to complete an address change at this time. Please deselect in order to continue."
          );
        } else if (showNoPoliciesError && !showApiError) {
          setError(
            "Change of address is not applicable for the client you have selected. Please deselect checkbox to continue with the transaction."
          );
        } else if (showNoPoliciesError && showApiError) {
          setError([
            "Change of address is not applicable for the client you have selected. Please deselect checkbox to continue with the transaction.",
            <br />,
            <br />,
            "Information for the client(s) you’ve selected is not available to complete an address change at this time. Please deselect in order to continue.",
          ]);
        } else {
          setError(
            "Please select the customer(s) for whom this address change should apply."
          );
        }
      } else if (authorizedToChangeNotChecked && !notMyAddressNotChecked) {
        setError(
          "The owner must be authenticated either verbally or in person prior to submitting this request. Please contact your Agency Supervisory Officer (ASO) with any questions. If you can accept the first acknowledgement statement, select the check box and press continue to proceed."
        );
      } else if (notMyAddressNotChecked && !authorizedToChangeNotChecked) {
        setError(
          "We cannot change a client’s address to the producer's address unless the producer has a role on the policy or is a family member of anyone listed on the policy. If you cannot accept the second acknowledgement statement, you may contact our service center for more information at 1-800-272-2216 Mon-Fri 8am-8pm ET. If you can accept the second acknowledgement, select the check box and press continue to proceed."
        );
      } else if (!areBothChecked) {
        setError(
          "If you cannot acknowledge these statements, you may contact our service center for more information at 1-800-272-2216 Mon-Fri 8am-8pm ET. If you can acknowledge these statements, select the check boxes and press continue to proceed."
        );
      } else if (
        atLeastOneUserSelected &&
        showApiError &&
        !showNoPoliciesError
      ) {
        setError(
          "Information for the client(s) you’ve selected is not available to complete an address change at this time. Please deselect in order to continue."
        );
      } else if (
        atLeastOneUserSelected &&
        !showApiError &&
        showNoPoliciesError
      ) {
        setError(
          "Change of address is not applicable for the client you have selected. Please deselect checkbox to continue with the transaction."
        );
      } else if (
        atLeastOneUserSelected &&
        showApiError &&
        showNoPoliciesError
      ) {
        setError([
          "Change of address is not applicable for the client you have selected. Please deselect checkbox to continue with the transaction.",
          <br />,
          <br />,
          "Information for the client(s) you’ve selected is not available to complete an address change at this time. Please deselect in order to continue.",
        ]);
      }
    }
  }
};

export const checkForErrorsPage2 = (props) => {
  const {
    editAddressCountry,
    setError,
    openAddressVerification,
    editAddressIsMilitaryAddress,
    nextPage,
    updateStateVariables,
  } = props;

  const { areRequiredFieldsFilled, HAS_ERRORS, ADDRESS3_BUT_NO_ADDRESS2 } =
    checkForRequiredFieldErrors(props);

  if (areRequiredFieldsFilled && !HAS_ERRORS && !ADDRESS3_BUT_NO_ADDRESS2) {
    if (updateStateVariables) {
      if (
        !editAddressIsMilitaryAddress?.value &&
        compareArrayOfStrings(
          ["UNITED STATES OF AMERICA", "USA"],
          editAddressCountry.value
        )
      ) {
        openAddressVerification();
      } else {
        nextPage();
      }
    }
    setError(null);
  } else if (!areRequiredFieldsFilled) {
    setError("Please fill out all required fields.");
  } else if (ADDRESS3_BUT_NO_ADDRESS2) {
    setError("Please fill address 2 before address 3.");
  } else if (HAS_ERRORS) {
    setError("Please fix input errors.");
  }
};

export const checkForErrorsPage3 = (props) => {
  const {
    formFields: { contactChoiceEmailInput },
    setError,
    nextPage,
  } = props;

  const NO_EMAIL_INPUTTED =
    hasError(contactChoiceEmailInput) ||
    !isDefined(contactChoiceEmailInput.value);

  if (NO_EMAIL_INPUTTED) {
    setError("Please provide your email address");
  } else {
    nextPage();
    setError(null);
  }
};

export const makeRequestBody = (props) => {
  const {
    user,
    servicingAgent,
    formData,
    selectedUsers,
    listOfMemberGuidForRetry = [],
  } = props;
  const {
    editAddressAddress1,
    editAddressAddress2,
    editAddressAddress3,
    editAddressCity,
    editAddressZipCode,
    editAddressState,
    editAddressCountry,
    uspsAddress,
    editAddressIsMilitaryAddress,
    editAddressMilitaryCity,
    editAddressMilitaryState,
    editAddressMilitaryZipCode,
  } = formData;

  const { homeAgencyId } = servicingAgent;

  const { isAnAgent } = getBpIdFromUser(user);

  const lengthOfAgencyId = 3;
  const orgUnit = isAnAgent
    ? getLastXChars(homeAgencyId, lengthOfAgencyId).padStart(
        lengthOfAgencyId,
        0
      )
    : "";
  let selectedUsersToBeSubmitted;
  if (listOfMemberGuidForRetry.length > 0) {
    selectedUsersToBeSubmitted = selectedUsers.filter((customer) => {
      const memberGUID = customer?.customer?.memberGUID;
      return containsArrayOfStrings(listOfMemberGuidForRetry, memberGUID);
    });
  } else {
    selectedUsersToBeSubmitted = selectedUsers;
  }

  const customers = selectedUsersToBeSubmitted.map((customer) => {
    const memberGUID = customer?.customer?.memberGUID;
    let customerEligibleAgreements = [];

    if (customer?.isUniquePpay) {
      customerEligibleAgreements =
        buildCustomerAgreementFromCurrentAgreement(customer);
    } else if (customer?.isNonOwnerInsured) {
      customerEligibleAgreements =
        buildCustomerAgreementFromCurrentAgreement(customer);

      const customerOtherAgreements =
        customer?.agreementsRequest?.data?.agreements || [];

      const otherAgreementsAfterRemovingDuplicate =
        customerOtherAgreements.filter(
          (agreement) =>
            agreement?.agreementKey !== customer?.agreementData?.agreementKey
        );

      customerEligibleAgreements.push(...otherAgreementsAfterRemovingDuplicate);
    } else {
      customerEligibleAgreements =
        customer?.agreementsRequest?.data?.agreements || [];
    }

    const customerAgreements = customerEligibleAgreements.reduce(
      (agreementRoleList, agreement) => {
        const { agreementKey, agreementCustomers = [] } = agreement;
        const rolesForAgreement = agreementCustomers.map(
          (agreementCustomer) => {
            const { roleType, roleSubType } = agreementCustomer;
            return {
              agreementKey,
              roleType,
              roleSubType,
              lostAddress: "N",
            };
          }
        );

        return [...agreementRoleList, ...rolesForAgreement];
      },
      []
    );
    return { memberGUID, customerAgreements };
  });

  let postalCode = editAddressIsMilitaryAddress.value
    ? editAddressMilitaryZipCode.value
    : editAddressZipCode.value;

  if (uspsAddress.value) {
    postalCode = uspsAddress.changedAddress.Zip.slice(0, 5);
  }

  return {
    address: {
      validationMessage: uspsAddress.value ? "COMP" : "UNK",
      validationDate: uspsAddress.value ? moment().format("YYYY-MM-DD") : "",
      line1: editAddressAddress1.value,
      line2: editAddressAddress2.value,
      line3: editAddressAddress3.value,
      city: editAddressIsMilitaryAddress.value
        ? editAddressMilitaryCity.value
        : editAddressCity.value,
      state: editAddressIsMilitaryAddress.value
        ? editAddressMilitaryState.value
        : editAddressState.value,
      countryName: editAddressCountry.value,
      countryCode: editAddressCountry.code,
      postalCode,
      postalCodeExtension1: uspsAddress.value
        ? uspsAddress.changedAddress.Zip.slice(-4)
        : "",
      domesticForeignCode: compareStrings(editAddressCountry.code, "USA")
        ? "DOM"
        : "FOR",
      customers,
    },
    requestorDetails: {
      email: getPreferredMethodOfContact(formData),
      orgUnit,
    },
  };
};

export const buildCustomerAgreementFromCurrentAgreement = (customer) => {
  const { agreementKey } = customer?.agreementData || {};
  const { arrayRoleType, arrayRoleSubType } = customer?.customer || {};
  const result = [{ agreementKey, agreementCustomers: [] }];
  arrayRoleType.forEach((roleType, index) => {
    result[0].agreementCustomers.push({
      roleType,
      roleSubType: arrayRoleSubType?.[index],
    });
  });

  return result;
};

export const checkAgreementSelectableAtChangeAddressStep1 = (
  agreement
  // customerDataFromSourcePolicy
) => {
  const { lineOfBusinessCode, agreementKeyAdmin } = agreement;

  const nonAllowedLOB = ["RETIRE"];
  if (compareArrayOfStrings(nonAllowedLOB, lineOfBusinessCode)) {
    return false;
  }

  const notAllowedAdmin = ["UNIV", "VUL", "HAVEN", "PALLM"];
  if (compareArrayOfStrings(notAllowedAdmin, agreementKeyAdmin)) {
    return false;
  }

  return true;
};

export const getCustomerAgreemenstGroupsByLOB = (
  agreementsData,
  customerDataFromSourcePolicy,
  priorityLobs
) => {
  const supportedRoleTypesForSelect = [
    userTypes.OWNER,
    userTypes.INSURED,
    userTypes.PREMIUM_PAYER,
  ];

  const initialCustomerAgreementGroupsByLob = { unsorted: [] };
  priorityLobs.forEach((lob) => {
    initialCustomerAgreementGroupsByLob[lob] = [];
  });

  return agreementsData.reduce((customerAgreementGroupsByLob, agreement) => {
    const {
      productTypeName,
      agreementKeyPrimaryDisplay,
      lineOfBusinessCode,
      agreementCustomers: agreementCustomerRoles,
    } = agreement;

    const isAgreementSelectableForChangeAddress =
      checkAgreementSelectableAtChangeAddressStep1(
        agreement,
        customerDataFromSourcePolicy
      );

    if (isAgreementSelectableForChangeAddress) {
      const rolesBasedOnAddress = agreementCustomerRoles.reduce(
        (addresses, role) => {
          const { roleType, memberGUID, address = {} } = role;
          if (memberGUID !== customerDataFromSourcePolicy.memberGUID) {
            return addresses;
          }

          if (!compareArrayOfStrings(supportedRoleTypesForSelect, roleType)) {
            return addresses;
          }

          const indexOfRepeatedAddress = addresses.findIndex(
            (item) => item.key === address.key
          );
          if (indexOfRepeatedAddress > -1) {
            addresses[indexOfRepeatedAddress].roleNames.push(
              getRoleName(roleType)
            );
            addresses[indexOfRepeatedAddress].roleNames.sort(
              (a, b) =>
                (b === roleNames.OWNR) - (a === roleNames.OWNR) ||
                (b === roleNames.INSD) - (a === roleNames.INSD) ||
                (b === roleNames.PPAY) - (a === roleNames.PPAY) ||
                a > b ||
                -(a < b)
            );
          } else {
            addresses.push({
              key: address.key,
              formattedAddress: formatAddress(
                address,
                true,
                "Unable to display"
              ),
              roleNames: [getRoleName(roleType)],
            });
          }
          return addresses;
        },
        []
      );

      if (compareArrayOfStrings(priorityLobs, lineOfBusinessCode)) {
        customerAgreementGroupsByLob[lineOfBusinessCode].push({
          productTypeName,
          agreementKeyPrimaryDisplay,
          roles: rolesBasedOnAddress,
        });
      } else {
        customerAgreementGroupsByLob.unsorted.push({
          productTypeName,
          agreementKeyPrimaryDisplay,
          roles: rolesBasedOnAddress,
        });
      }
    }
    return customerAgreementGroupsByLob;
  }, initialCustomerAgreementGroupsByLob);
};

export const getFormattedCustomerAgreements = (
  agreementsData,
  customerDataFromSourcePolicy
) => {
  const customerAgreements = [];
  const priorityLobs = ["LIFE", "ANN", "DI", "LTC"];

  const customerAgreementsbyLob = getCustomerAgreemenstGroupsByLOB(
    agreementsData,
    customerDataFromSourcePolicy,
    priorityLobs
  );

  priorityLobs.forEach((lob) => {
    const agreementsOfLob = customerAgreementsbyLob[lob];
    if (isArray(agreementsOfLob)) {
      customerAgreements.push(...agreementsOfLob);
    }
  });

  const agreementsOfUnsortedLob = customerAgreementsbyLob.unsorted;
  if (isArray(agreementsOfUnsortedLob)) {
    customerAgreements.push(...agreementsOfUnsortedLob);
  }
  return customerAgreements;
};

export const getDescription = (props) => {
  const { userId } = props;
  switch (userId) {
    case "RPA3698":
      return "TradVAddChg Bot";
    case "DMSACL":
      return "Life Address Changes";
    default:
      return "ACE BOT";
  }
};

export const addCurrentPolicyToUser = (props) => {
  const { customerAgreements, agreementData, memberGUID, customer } = props;
  if (!customerAgreements || !agreementData) {
    return customerAgreements;
  }
  if (customerAgreements.length === 0) {
    const { agreementCustomers } = agreementData;
    const ppayAgreementCustomers = agreementCustomers.filter(
      (agreementCustomer) => {
        if (isDefined(customer.roleSubType)) {
          return (
            agreementCustomer.memberGUID === memberGUID &&
            compareStrings(agreementCustomer.roleType, customer.roleType) &&
            compareStrings(agreementCustomer.roleSubType, customer.roleSubType)
          );
        }
        return (
          agreementCustomer.memberGUID === memberGUID &&
          compareStrings(agreementCustomer.roleType, customer.roleType)
        );
      }
    );
    const ppayCustomerAgreement = {
      agreementKey: agreementData?.agreementKey,
      agreementKeyAdmin: agreementData?.agreementKeyAdmin,
      agreementKeyPrimaryDisplay: trimStart(
        agreementData?.agreementKeyPrimary,
        "0"
      ),
      lineOfBusinessCode: agreementData?.lineOfBusinessCode,
      majorProductCode: agreementData?.majorProductCode,
      productTypeName: agreementData?.productTypeName,
      agreementCustomers: ppayAgreementCustomers,
    };
    customerAgreements.push(ppayCustomerAgreement);
  }
  return customerAgreements;
};

export const useChangeAddressFields = ({ agreementData }) => {
  const { agreementCustomers, minorProductName, policyId, agreementKeyAdmin } =
    agreementData;

  const ownerInsuredGuids = [];
  const selectableCustomers = agreementCustomers?.filter((user) => {
    const acceptedRoleTypes = ["INSD", "OWNR", "PPAY"];
    if (compareArrayOfStrings(["INSD", "OWNR"], user.roleType)) {
      ownerInsuredGuids.push(user.memberGUID);
    }
    return compareArrayOfStrings(acceptedRoleTypes, user.roleType);
  });

  const peopleListUnsorted = getPeople(selectableCustomers);
  const peopleList = sortPeopleByRoles(peopleListUnsorted).map((user) => ({
    ...user,
    customerName: formatName({
      nameObject: user,
      format: "firstNameDisplayedFirst",
    }),
    formattedAddress: formatAddress(user.address),
    policy: `${minorProductName} #${policyId}`,
  }));

  const generatedCustomers = peopleList.reduce((users, customer, index) => {
    const fieldName = `customer${index}`;
    let isUniquePpay = false;
    let isNonOwnerInsured = false;
    if (
      compareStrings("PPAY", customer.roleType) &&
      !compareArrayOfStrings(ownerInsuredGuids, customer.memberGUID)
    ) {
      isUniquePpay = true;
    }
    if (
      compareArrayOfStrings(customer.arrayRoleType, "INSD") &&
      !compareArrayOfStrings(customer.arrayRoleType, "OWNR")
    ) {
      isNonOwnerInsured = true;
    }

    return {
      ...users,
      [fieldName]: {
        id: fieldName,
        label: customer.customerName,
        agreementData,
        name: fieldName,
        value: false,
        customer,
        labelBold: true,
        agreementKeyAdmin,
        agreementsRequest: {},
        policies: [],
        isUniquePpay,
        isNonOwnerInsured,
        apiError: false,
        hasExecutedAtLeastOnce: false,
      },
    };
  }, {});

  const contactChoiceEmail = useRequestorContactEmail();

  return {
    ...generatedCustomers,
    totalCustomers: peopleList.length,
    authorizedToChange: {
      id: "authorizedToChange",
      label: AUTHORIZATION_CONFIRMATION_STATEMENT,
      name: "authorizedToChange",
      value: false,
      required: true,
    },
    notmyAddress: {
      id: "notmyAddress",
      label:
        "I understand I cannot change a client's address to my home or agency address unless a role on the policy/contract is a family member of anyone listed on the policy.",
      name: "notmyAddress",
      value: false,
      required: true,
    },
    contactChoiceEmailInput: {
      id: "contactChoiceEmailInput",
      hideLabel: true,
      ariaLabel: "Email input",
      placeholder: "Please type your email address",
      name: "contactChoiceEmailInput",
      value: contactChoiceEmail,
      uncheckedValue: "",
      errors: [],
      postpone: true,
      useValidation: useEmailValidation,
      disabled: false,
    },
    contactChoiceConfirmEmailInput: {
      id: "contactChoiceConfirmEmailInput",
      hideLabel: true,
      ariaLabel: "Confirm Email input",
      placeholder: "Please type your email address",
      name: "contactChoiceConfirmEmailInput",
      value: "",
      uncheckedValue: "",
      errors: [],
      postpone: true,
      emailFormFieldValue: contactChoiceEmail,
      useValidation: useConfirmEmailValidation,
      disabled: false,
      disablePaste: true,
    },
    ...useEditAddressFields(),
  };
};

export const useCustomerCheckboxProps = (props) => {
  const { formField } = props;
  const { dispatchForm } = useFormContext();
  const customerEligibilityRequest = useMakeRequest({
    apiRequest: "fetchCustomerEligibilityData",
    apiParams: {
      memberGUID: formField?.customer?.memberGUID || "",
    },
    immediateRequest: false,
    dataSelector: "processCustomerEligibilityData",
  });

  const customerAgreementsFromEligibilty =
    customerEligibilityRequest.data?.agreements || [];
  const agreementData = formField?.agreementData;
  let customerAgreements = [];

  if (
    formField?.value &&
    (formField?.isUniquePpay || formField?.isNonOwnerInsured)
  ) {
    addCurrentPolicyToUser({
      customerAgreements,
      agreementData,
      memberGUID: formField?.customer?.memberGUID,
      customer: formField?.customer,
    });
  }
  if (!formField?.isUniquePpay && !formField?.isNonOwnerInsured) {
    customerAgreements = customerAgreementsFromEligibilty;
  }

  if (formField?.isNonOwnerInsured) {
    customerAgreements = customerAgreements.concat(
      customerAgreementsFromEligibilty
    );
  }

  useEffect(() => {
    if (
      (isEmptyObject(formField.agreementsRequest) ||
        !formField.agreementsRequest.hasExecutedAtLeastOnce()) &&
      !formField?.isUniquePpay
    ) {
      dispatchForm({
        type: types.REPLACE_FIELD,
        formField: {
          ...formField,
          agreementsRequest: customerEligibilityRequest,
          policies: getFormattedCustomerAgreements(
            customerAgreements,
            formField.customer
          ),
          apiError: isDefined(customerEligibilityRequest.error),
          hasExecutedAtLeastOnce: true,
        },
      });
    }
  }, [customerEligibilityRequest.isLoading]);

  useEffect(() => {
    if (formField?.isUniquePpay && formField?.value) {
      dispatchForm({
        type: types.REPLACE_FIELD,
        formField: {
          ...formField,
          agreementsRequest: customerEligibilityRequest,
          policies: getFormattedCustomerAgreements(
            customerAgreements,
            formField.customer
          ),
          apiError: isDefined(customerEligibilityRequest.error),
          hasExecutedAtLeastOnce: true,
        },
      });
    }
  }, [formField?.isUniquePpay, formField?.value]);

  useEffect(() => {
    if (formField?.isNonOwnerInsured && formField?.value) {
      dispatchForm({
        type: types.REPLACE_FIELD,
        formField: {
          ...formField,
          agreementsRequest: customerEligibilityRequest,
          policies: getFormattedCustomerAgreements(
            customerAgreements,
            formField.customer
          ),
          apiError: isDefined(customerEligibilityRequest.error),
          hasExecutedAtLeastOnce: true,
        },
      });
    }
  }, [formField?.isNonOwnerInsured, formField?.value]);

  return {
    onChange: () => {
      const isChecked = !formField.value;
      dispatchForm({ type: types.UPDATE_TOGGLE, formField });
      if (
        isChecked &&
        !formField?.isUniquePpay &&
        !formField.agreementsRequest.isLoading &&
        !formField.agreementsRequest.hasExecutedAtLeastOnce()
      ) {
        formField.agreementsRequest.executeRequest();
      }
      if (
        !isChecked &&
        !formField.agreementsRequest.isLoading &&
        !formField.agreementsRequest.hasExecutedAtLeastOnce()
      ) {
        dispatchForm({
          type: types.REPLACE_FIELD,
          formField: {
            ...formField,
            apiError: false,
            value: false,
          },
        });
      }
    },
    className: "custom-control-input",
  };
};

export const verifyChangeAddress = (props) => {
  const {
    prependPolicyDetails = false,
    data: {
      status,
      statusReason,
      agreementKeyAdmin,
      pendingSettlementIndicator,
      specialConditionCode,
      specialCondition2Code,
      agreementKey,
      lineOfBusinessCode,
    },
    transactionSupportedPolicies,
    billingData,
    annuityData,
  } = props;

  const { isTransactionAllowed } = checkIfTransactionIsAllowed({
    transactionSupportedPolicies,
    transactionName: supportedTransactions.ChangeAddress,
    agreementData: props.data,
  });

  const unsupportedIndicators = ["1", "4", "5", "6", "7", "8"];
  const badSpecialConditionCodes = [
    "CO",
    "LG",
    "SC",
    "TL",
    "SL",
    "MR",
    "B",
    "CAU",
    "OD",
    "WLR",
  ];

  const policyDetailsParam = prependPolicyDetails ? "/policyDetails/" : "";
  const returnObject = {
    redirectLink: `${policyDetailsParam}${encodeURIComponent(
      agreementKey
    )}/changeAddress`,
    showAlert: true,
    unavailable: true,
    isTransactionAllowed,
  };

  const { billType } = annuityData?.billing ? annuityData.billing : "";
  if (
    !isAgreementDataEligibleforChangeAddress({
      agreementData: props?.data,
      transactionSupportedPolicies,
      isTransactionAllowed,
    }) ||
    !isBillingDataEligibleForChangeAddress({ billingData, billType })
  ) {
    return { ...returnObject, showAlert: false, unavailable: true };
  }

  if (
    !(isTransactionAllowed && compareStrings(lineOfBusinessCode, "LIFE")) &&
    compareStrings(status, "IF")
  ) {
    return { ...returnObject, showAlert: false, unavailable: false };
  }
  if (!(isTransactionAllowed && compareStrings(lineOfBusinessCode, "LIFE"))) {
    return { ...returnObject, showAlert: false };
  }
  if (!compareStrings(status, "IF")) {
    return { ...returnObject, showAlert: false };
  }
  if (compareStrings(statusReason, "DP")) {
    return returnObject;
  }
  if (compareStrings(statusReason, "CP")) {
    return { ...returnObject, showAlert: false };
  }
  if (compareStrings(statusReason, "SP")) {
    return returnObject;
  }
  if (
    compareStrings(agreementKeyAdmin, "OPM") &&
    compareArrayOfStrings(unsupportedIndicators, pendingSettlementIndicator)
  ) {
    return returnObject;
  }
  if (compareStrings(statusReason, "PT")) {
    return returnObject;
  }
  if (
    compareStrings(agreementKeyAdmin, "VNTG1") &&
    (compareArrayOfStrings(badSpecialConditionCodes, specialConditionCode) ||
      compareArrayOfStrings(badSpecialConditionCodes, specialCondition2Code))
  ) {
    return returnObject;
  }

  return {
    ...returnObject,
    showAlert: false,
    unavailable: false,
    isTransactionAllowed,
  };
};
