import { useState } from "react";
import { useParams } from "react-router-dom";
import useDeepCompareEffect from "use-deep-compare-effect";
import { useMakeRequest } from "../../hooks/api";
import { isDefined } from "../../utils/evaluate";
import {
  deepLinkType,
  supportedTransactions,
  userTypes,
} from "../../utils/translate";
import { compareArrayOfStrings, compareStrings } from "../../utils/string";
import { AGREEMENT_NOT_FOUND } from "../../constants/ui";
import {
  isAgreementDataEligibleForDirectLinks,
  verifyDirectLinks,
} from "./restrictions";
import { types, useFormContext } from "../../contexts/form";
import {
  getBest,
  getBpIdFromUser,
  getFirstServicingAgent,
  getTrimmedPolicyIdFromAgreement,
  getUserBySubType,
  getUsers,
  isHomeOfficeUser,
} from "../../utils/findData";
import { useUserState } from "../../contexts/user";
import {
  fireTealiumAndFollowLink,
  fireTealiumEvent,
} from "../../utils/tealium";
import { useEmailValidation } from "../../utils/validation";
import BeneficiaryChangeModal from "./linkHelpModals/beneficiaryChangeModal";
import AddressChangeModal from "./linkHelpModals/addressChangeModal";
import CashValueQuoteModal from "./linkHelpModals/cashValueQuoteModal";
import HistoricalCashValueModal from "./linkHelpModals/historicalCashValueModal";
import NameChangeModal from "./linkHelpModals/nameChangeModal";
import OwnerChangeModal from "./linkHelpModals/ownerChangeModal";
import PayBillModal from "./linkHelpModals/payBillModal";
import PolicyCoverageSummaryModal from "./linkHelpModals/policyCoverageSummaryModal";
import TransactionStatusModal from "./linkHelpModals/transactionStatusModal";
import DocumentHubModal from "./linkHelpModals/documentHubModal";
import PaperlessPreferenceModal from "./linkHelpModals/paperlessPreferenceModal";
import DisabilityClaimStatusModal from "./linkHelpModals/disabilityClaimStatusModal";
import LoanRequestModal from "./linkHelpModals/loanRequestModal";
import InitiateDisabilityClaimModal from "./linkHelpModals/initiateDisabilityClaimModal";
import FileDisabilityClaimModal from "./linkHelpModals/fileDisabilityClaimModal";
import { useLDValue } from "../common/featureFlags/hooks";
import {
  getCatsDivAndDeptCodeByLineOfBusinessCode,
  isRegisteredOnMM,
} from "../../utils/policies";
import { sendDirectLinksNotificationEmail } from "../../api";
import {
  formatName,
  getLastXChars,
  nameFormattingOptions,
} from "../../utils/format";
import {
  formatDate,
  getCurrentDate,
  getCurrentDateTimeWithTimezone,
} from "../../utils/dates";
import { typesOfAgents } from "../../data/typesOfAgents";

export const useDirectLinksProps = () => {
  const { agreementKey } = useParams();
  const [pageNumber, setPageNumber] = useState(1);
  const [showErrorPage, setShowErrorPage] = useState(false);
  const [error, setError] = useState(null);
  const [errorText, setErrorText] = useState("");
  const [showLoadingIcon, setShowLoadingIcon] = useState(false);
  const { user } = useUserState();
  const { trimmedPolicyId } = getTrimmedPolicyIdFromAgreement(agreementKey);
  const { email } = user;

  const nextPage = () => setPageNumber(pageNumber + 1);
  const lastPage = () => setPageNumber(pageNumber - 1);
  const previousPage = () => {
    fireTealiumEvent({
      label: "SVNT",
      action: `${supportedTransactions.DirectLinks} PageNumber ${pageNumber} previous step button clicked`,
      category: `${supportedTransactions.DirectLinks}`,
      value: trimmedPolicyId,
      custId: email,
    });
    lastPage();
    setError(null);
  };

  const shouldDisableSubmit = isHomeOfficeUser(user);

  const validRequest = (dependentAgreementData) => {
    const { isUnauthorized } = dependentAgreementData;
    const { type, description } = dependentAgreementData?.messages || {};
    return (
      !isUnauthorized &&
      compareStrings(type, "SUCCESS") &&
      !compareStrings(description, AGREEMENT_NOT_FOUND) &&
      isAgreementDataEligibleForDirectLinks(dependentAgreementData)
    );
  };

  const billingRequest = useMakeRequest({
    apiRequest: "fetchBillingInformation",
    apiParams: { agreementKey, showExtended: false },
    dataSelector: "processBillingInformation",
    immediateRequest: false,
    updateRequestCallback: (dependentAgreementData) => {
      const { issueDate, jurisdictionState } = dependentAgreementData;
      const dataForSelectors = isDefined(issueDate)
        ? { jurisdictionState }
        : { issueDate, jurisdictionState };
      return { dataForSelectors };
    },
    canExecuteCallBack: validRequest,
  });

  const producerRequest = useMakeRequest({
    apiRequest: "fetchProducersData",
    apiParams: { agreementKey },
    immediateRequest: false,
    dataSelector: "processProducersData",
    canExecuteCallBack: validRequest,
  });

  const agreementRequest = useMakeRequest({
    apiRequest: "fetchAgreementData",
    apiParams: { agreementKey },
    immediateRequest: true,
    dataSelector: "processAgreementRequest",
    dependentRequests: [billingRequest, producerRequest],
  });
  const agreementData = agreementRequest.data || {};
  const { agreementCustomers, policyId, lineOfBusinessCode, isPolicyNotFound } =
    agreementData;

  const isPolicyLoaded = !agreementRequest.isLoading && isDefined(policyId);

  const transactionSupportedPolicies = useLDValue({
    flagName: ["transactionSupportedPolicies"],
  });

  const { isEligible, isTransactionAllowed, isUnauthorized } =
    verifyDirectLinks({
      agreementData,
      transactionSupportedPolicies,
    });

  const displayDeepLinksFlow = isEligible && isTransactionAllowed;

  const { agentType } = getBpIdFromUser(user);
  const isMmsdOnlyUser = compareStrings(agentType, typesOfAgents.MMSD);

  const eligibleOwners = getEligibleOwners(agreementCustomers);

  const eligibleOwnerFields = getEligibleOwnerFields(eligibleOwners);

  const fields = {
    ...eligibleOwnerFields,
    eligibleOwnerCount: eligibleOwners.length,
    addressChange: {
      id: "addressChange",
      labelComponent: AddressChangeModal,
      name: "addressChange",
      value: false,
      type: "linkCheckbox",
      directLink: "",
      displayName: "Address Change",
    },
    beneficiaryChange: {
      id: "beneficiaryChange",
      labelComponent: BeneficiaryChangeModal,
      name: "beneficiaryChange",
      value: false,
      type: "linkCheckbox",
      directLink: "",
      displayName: "Beneficiary Change",
    },
    cashValueQuote: {
      id: "cashValueQuote",
      labelComponent: CashValueQuoteModal,
      name: "cashValueQuote",
      value: false,
      type: "linkCheckbox",
      directLink: "",
      displayName: "Cash Value Quote",
    },
    historicalCashValues: {
      id: "historicalCashValues",
      labelComponent: HistoricalCashValueModal,
      name: "historicalCashValues",
      value: false,
      type: "linkCheckbox",
      directLink: "",
      displayName: "Historical Cash Values",
    },
    loanRequest: {
      id: "loanRequest",
      labelComponent: LoanRequestModal,
      name: "loanRequest",
      value: false,
      type: "linkCheckbox",
      directLink: "",
      displayName: "Loan Request",
    },
    nameChange: {
      id: "nameChange",
      labelComponent: NameChangeModal,
      name: "nameChange",
      value: false,
      type: "linkCheckbox",
      directLink: "",
      displayName: "Name Change",
    },
    ownerChange: {
      id: "ownerChange",
      labelComponent: OwnerChangeModal,
      name: "ownerChange",
      value: false,
      type: "linkCheckbox",
      directLink: "",
      displayName: "Owner Change",
    },
    payBill: {
      id: "payBill",
      labelComponent: PayBillModal,
      name: "payBill",
      value: false,
      type: "linkCheckbox",
      directLink: "",
      displayName: "Pay a Bill",
    },
    policyCoverageSummary: {
      id: "policyCoverageSummary",
      labelComponent: PolicyCoverageSummaryModal,
      name: "policyCoverageSummary",
      value: false,
      type: "linkCheckbox",
      directLink: "",
      displayName: "Policy Coverage Summary",
    },
    transactionStatus: {
      id: "transactionStatus",
      labelComponent: TransactionStatusModal,
      name: "transactionStatus",
      value: false,
      type: "linkCheckbox",
      directLink: "",
      displayName: "Transaction Status",
    },
    documentHub: {
      id: "documentHub",
      labelComponent: DocumentHubModal,
      name: "documentHub",
      value: false,
      type: "linkCheckbox",
      directLink: "",
      displayName: "Document Hub",
    },
    initiateDisabilityClaim: {
      id: "initiateDisabilityClaim",
      labelComponent: InitiateDisabilityClaimModal,
      name: "initiateDisabilityClaim",
      value: false,
      type: "linkCheckbox",
      directLink: "",
      displayName: "Initiate Disability Claim",
    },
    fileDisabilityClaim: {
      id: "fileDisabilityClaim",
      labelComponent: FileDisabilityClaimModal,
      name: "fileDisabilityClaim",
      value: false,
      type: "linkCheckbox",
      directLink: "",
      displayName: "File a Disability Claim",
    },
    paperlessPreferences: {
      id: "paperlessPreferences",
      labelComponent: PaperlessPreferenceModal,
      name: "paperlessPreferences",
      value: false,
      type: "linkCheckbox",
      directLink: "",
      displayName: "Paperless Preferences",
    },
    disabilityClaimStatus: {
      id: "disabilityClaimStatus",
      labelComponent: DisabilityClaimStatusModal,
      name: "disabilityClaimStatus",
      value: false,
      type: "linkCheckbox",
      directLink: "",
      displayName: "Disability Claim Status & Document Hub",
    },
  };

  const onSubmit = (formData) => {
    const reqBody = makeDirectLinksEmailRequestBody({
      formData,
      user,
      agreementData,
      producerRequest,
    });

    const headers = {
      Authorization: `Bearer ${user.accessToken}`,
    };

    setShowLoadingIcon(true);

    const selectedDirectLinks = getSelectedDirectLinks(formData);

    selectedDirectLinks.forEach((directLinkSelected) => {
      fireTealiumEvent({
        label: "SVNT",
        action: `${lineOfBusinessCode}: ${supportedTransactions.DirectLinks} submitted for ${directLinkSelected.displayName}`,
        category: supportedTransactions.DirectLinks,
        value: trimmedPolicyId,
        custId: email,
      });
    });

    sendDirectLinksNotificationEmail({ headers, apiParams: reqBody })
      .then((result) => {
        if (result?.status === 200) {
          setShowLoadingIcon(false);
          fireTealiumEvent({
            label: "SVNT",
            action: `${lineOfBusinessCode}: ${supportedTransactions.DirectLinks} submitted`,
            category: `${supportedTransactions.DirectLinks}`,
            value: trimmedPolicyId,
            custId: email,
          });
          setPageNumber(4);
        }
      })
      .catch((e) => {
        fireTealiumEvent({
          label: "SVNT",
          action: `${lineOfBusinessCode}: ${supportedTransactions.DirectLinks} Submission Failed`,
          category: `${supportedTransactions.DirectLinks}`,
          value: policyId,
          custId: user?.email,
        });
        setErrorText(
          compareStrings("Network Error", e.message)
            ? "The page you are looking for is temporarily unavailable."
            : "There was an error processing your request; please try again later."
        );
        setShowErrorPage(true);
      });
  };

  return {
    fields,
    agreementRequest,
    billingRequest,
    producerRequest,
    isPolicyLoaded,
    pageNumber,
    nextPage,
    previousPage,
    onSubmit,
    shouldDisableSubmit,
    error,
    lineOfBusinessCode,
    displayDeepLinksFlow,
    setShowErrorPage,
    showErrorPage,
    errorText,
    showLoadingIcon,
    isUnauthorized,
    isMmsdOnlyUser,
    isPolicyNotFound,
  };
};

export const makeDirectLinksEmailRequestBody = (props) => {
  const { formData, user, agreementData, producerRequest } = props;
  const {
    agreementKeyPrimary,
    agreementKeyAdmin,
    agreementKeyPrefix,
    lineOfBusinessCode,
    agreementCustomers,
  } = agreementData;
  let recipientList = "";
  let selectedLinks = "";
  const emailRecipients = [];
  const selectedDirectLinksData = {};
  const requestorName = `${user.firstName} ${user.lastName}`;
  const requestorEmail = user?.email;
  const directLinksSenderObject = {
    email: user?.email,
    name: requestorName,
  };
  const { selectedOwnerFields, selectedOwnerEmailFields } =
    getSelectedOwnersAndEmails(formData);
  const { producers } = producerRequest?.data || {};
  const servicingAgent = getFirstServicingAgent({
    agents: producers,
  });
  const { businessPartnerId, homeAgencyId } = servicingAgent;
  const receivedDateTime = formatDate(getCurrentDate());

  const lengthOfAgencyId = 3;
  const { isAnAgent, idNumber } = getBpIdFromUser(user);
  const orgUnit = isAnAgent
    ? getLastXChars(homeAgencyId, lengthOfAgencyId).padStart(
        lengthOfAgencyId,
        0
      )
    : "";
  const currentTimeLongCommentFormatted = getCurrentDateTimeWithTimezone();
  const personInsured = getUserBySubType({
    type: userTypes.INSURED,
    subType: "PRMR",
    people: agreementCustomers,
  });
  const {
    firstName: insuredFirstName = "",
    lastName: insuredLastName = "",
    middleName: insuredMiddleName = "",
  } = personInsured || {};
  const requestHelper = supportedTransactions.DirectLinks;

  const longComment = `Transaction Type:TRANSACTION_TYPE\\n\\nRequestor Contact Info: \\n  Name: ${requestorName}\\n  Method: E-mail\\n  Detail: ${requestorEmail}\\n\\nOperator Name: ${requestorName} ID: ${idNumber} Org Unit: ${orgUnit}\\nSubmitted Date/Time: ${currentTimeLongCommentFormatted}\\n\\nDirect Link Recipient Email: RECIPIENT_EMAIL`;

  selectedOwnerFields.map((owner, index) => {
    const ownerEmail = selectedOwnerEmailFields[index].value;
    const recipientObject = {
      email: ownerEmail,
      firstName: formatName({
        nameObject: { firstName: getDirectLinksRecipientName(owner) },
        format: nameFormattingOptions.firstNameDisplayedFirst,
      }),
    };
    emailRecipients.push(recipientObject);
    recipientList = `${recipientList}, ${ownerEmail}`.replace(/^,/, "");
    return { emailRecipients, recipientList };
  });

  Object.keys(formData).forEach((item) => {
    const selectedDirectLink = formData[item];
    if (
      compareStrings(selectedDirectLink?.type, "linkCheckbox") &&
      selectedDirectLink.value
    ) {
      if (compareStrings(selectedDirectLink?.name, "addresschange")) {
        selectedDirectLinksData.showAddressChangeLink = "true";
        selectedDirectLinksData.addressChangeLinkURL =
          selectedDirectLink.directLink;
      }
      if (compareStrings(selectedDirectLink?.name, "beneficiarychange")) {
        selectedDirectLinksData.showBeneficiaryChangeLink = "true";
        selectedDirectLinksData.beneficiaryChangeLinkURL =
          selectedDirectLink.directLink;
      }
      if (compareStrings(selectedDirectLink?.name, "cashvaluequote")) {
        selectedDirectLinksData.showCashValueQuoteLink = "true";
        selectedDirectLinksData.cashValueQuoteLinkURL =
          selectedDirectLink.directLink;
      }
      if (compareStrings(selectedDirectLink?.name, "loanrequest")) {
        selectedDirectLinksData.showLoanRequestLink = "true";
        selectedDirectLinksData.loanRequestLinkURL =
          selectedDirectLink.directLink;
      }
      if (compareStrings(selectedDirectLink?.name, "documentHub")) {
        selectedDirectLinksData.showDocHubLink = "true";
        selectedDirectLinksData.docHubLinkURL = selectedDirectLink.directLink;
      }
      if (compareStrings(selectedDirectLink?.name, "historicalcashvalues")) {
        selectedDirectLinksData.showHistoricalCashValuesLink = "true";
        selectedDirectLinksData.historicalCashValuesLinkURL =
          selectedDirectLink.directLink;
      }
      if (compareStrings(selectedDirectLink?.name, "initiatedisabilityclaim")) {
        selectedDirectLinksData.showInitiateDisabilityClaimLink = "true";
        selectedDirectLinksData.initiateDisabilityClaimLinkURL =
          selectedDirectLink.directLink;
      }
      if (compareStrings(selectedDirectLink?.name, "filedisabilityclaim")) {
        selectedDirectLinksData.showFileDisabilityClaimLink = "true";
        selectedDirectLinksData.fileDisabilityClaimLinkURL =
          selectedDirectLink.directLink;
      }
      if (compareStrings(selectedDirectLink?.name, "namechange")) {
        selectedDirectLinksData.showNameChangeLink = "true";
        selectedDirectLinksData.nameChangeLinkURL =
          selectedDirectLink.directLink;
      }
      if (compareStrings(selectedDirectLink?.name, "ownerchange")) {
        selectedDirectLinksData.showOwnerChangeLink = "true";
        selectedDirectLinksData.ownerChangeLinkURL =
          selectedDirectLink.directLink;
      }
      if (compareStrings(selectedDirectLink?.name, "paybill")) {
        selectedDirectLinksData.showPayBillLink = "true";
        selectedDirectLinksData.payBillLinkURL = selectedDirectLink.directLink;
      }
      if (compareStrings(selectedDirectLink?.name, "policycoveragesummary")) {
        selectedDirectLinksData.showPolicyCoverageSummaryLink = "true";
        selectedDirectLinksData.policyCoverageSummaryLinkURL =
          selectedDirectLink.directLink;
      }
      if (compareStrings(selectedDirectLink?.name, "transactionstatus")) {
        selectedDirectLinksData.showTransactionStatusLink = "true";
        selectedDirectLinksData.transactionStatusLinkURL =
          selectedDirectLink.directLink;
      }
      if (compareStrings(selectedDirectLink?.name, "paperlesspreferences")) {
        selectedDirectLinksData.showPaperlessPreferencesLink = "true";
        selectedDirectLinksData.paperlessPreferencesLinkURL =
          selectedDirectLink.directLink;
      }
      if (compareStrings(selectedDirectLink?.name, "disabilityclaimstatus")) {
        selectedDirectLinksData.showDiClaimStatusAndDocUploadLink = "true";
        selectedDirectLinksData.diClaimStatusAndDocUploadLinkURL =
          selectedDirectLink.directLink;
      }
      selectedLinks =
        `${selectedLinks}, ${selectedDirectLink?.displayName}`.replace(
          /^,/,
          ""
        );
    }
  });

  const directLinkCatsEventData = {
    workEvent: "11148",
    assignTo: "SERVNET",
    priorityCode: "10",
    requestorTypeCode: "A",
    serviceChannelSourceCode: "19",
    lastUpdatedBy: "SVCNET2",
    loggedBy: "SVCNET2",
    userId: "SVCNET2",
    completionInd: "Y",
    agreementNumber: agreementKeyPrimary,
    carrierAdminSystem: agreementKeyAdmin,
    agreementNumberPrefix: agreementKeyPrefix,
    agentId: businessPartnerId.toString(),
    receivedDate: receivedDateTime,
    insuredFirstName,
    insuredLastName,
    insuredMiddleName,
    shortComment: "Direct Link Request",
    divCode:
      getCatsDivAndDeptCodeByLineOfBusinessCode(lineOfBusinessCode)?.divCode,
    deptCode:
      getCatsDivAndDeptCodeByLineOfBusinessCode(lineOfBusinessCode)?.deptCode,
    requestHelper,
    longComment,
    requestorName,
  };

  return {
    sender: directLinksSenderObject,
    recipients: emailRecipients,
    itemData: {
      copyrightYear: new Date().getFullYear().toString(),
      ...selectedDirectLinksData,
      ...directLinkCatsEventData,
      recipientList,
      selectedLinks,
    },
  };
};

export const getDirectLinksRecipientName = (owner) => {
  const { firstName, label } = owner;
  let firstNameResult;
  if (isDefined(firstName)) {
    firstNameResult = firstName;
  } else if (isDefined(label)) {
    firstNameResult = label;
  } else {
    firstNameResult = "Hello";
  }
  return firstNameResult.replace(/,*$/, "");
};

export const useSelectDirectLinksProps = (props) => {
  const { agreementRequest, billingRequest } = props;
  const { formData, dispatchForm } = useFormContext();

  const agreementData = agreementRequest?.data || {};
  const billingData = billingRequest?.data || {};
  const { paperlessPreferences } = billingData;
  const {
    canEditPaperlessPreference,
    showPaperlessPreference,
    paperlessPreference,
  } = paperlessPreferences || {};

  const {
    lineOfBusinessCode,
    transactionDeepLinks = [],
    requestAddressChangeIndicator,
    requestManageBeneficiaryIndicator,
    requestCashValueQuoteIndicator,
    requestCashValueQuoteHistoryIndicator,
    requestNameChangeIndicator,
    requestOwnershipChangeIndicator,
    requestPayABillIndicator,
    requestPolicyVerificationIndicator,
    requestTransactionStatusIndicator,
    requestDIClaimIndicator,
    requestFileDIClaimIndicator,
    DIClaimIndicator,
  } = agreementData;

  const directLinkOptionsToShow = {};

  transactionDeepLinks.forEach((link) => {
    switch (link.type) {
      case deepLinkType.ADDRESS_CHANGE:
        if (compareStrings("Yes", requestAddressChangeIndicator)) {
          directLinkOptionsToShow.addressChange = link.trxDeepLink;
        }
        break;
      case deepLinkType.BENEFICIARY_CHANGE:
        if (compareStrings("Yes", requestManageBeneficiaryIndicator)) {
          directLinkOptionsToShow.beneficiaryChange = link.trxDeepLink;
        }
        break;
      case deepLinkType.CASH_VALUE_QUOTE:
        if (compareStrings("Yes", requestCashValueQuoteIndicator)) {
          directLinkOptionsToShow.cashValueQuote = link.trxDeepLink;
        }
        if (compareStrings("Yes", requestCashValueQuoteHistoryIndicator)) {
          directLinkOptionsToShow.historicalCashValues = link.trxDeepLink;
        }
        break;
      case deepLinkType.LOAN_REQUEST: {
        if (isEligibleForLoanRequestLink(agreementData)) {
          directLinkOptionsToShow.loanRequest = link.trxDeepLink;
        }
        break;
      }
      case deepLinkType.NAME_CHANGE:
        if (compareStrings("Yes", requestNameChangeIndicator)) {
          directLinkOptionsToShow.nameChange = link.trxDeepLink;
        }
        break;
      case deepLinkType.OWNER_CHANGE:
        if (compareStrings("Yes", requestOwnershipChangeIndicator)) {
          directLinkOptionsToShow.ownerChange = link.trxDeepLink;
        }
        break;
      case deepLinkType.PAY_BILL:
        if (compareStrings("Yes", requestPayABillIndicator)) {
          directLinkOptionsToShow.payBill = link.trxDeepLink;
        }
        break;
      case deepLinkType.POLICY_COVERAGE_SUMMARY:
        if (compareStrings("Yes", requestPolicyVerificationIndicator)) {
          directLinkOptionsToShow.policyCoverageSummary = link.trxDeepLink;
        }
        break;
      case deepLinkType.TRANSACTION_STATUS:
        if (compareStrings("Yes", requestTransactionStatusIndicator)) {
          directLinkOptionsToShow.transactionStatus = link.trxDeepLink;
        }
        break;
      case deepLinkType.DOCHUB:
        if (compareArrayOfStrings(["LIFE", "DI", "ANN"], lineOfBusinessCode)) {
          directLinkOptionsToShow.documentHub = link.trxDeepLink;
        }
        break;
      case deepLinkType.DISABILITY_CLAIM:
        if (compareStrings("Yes", requestDIClaimIndicator)) {
          directLinkOptionsToShow.initiateDisabilityClaim = link.trxDeepLink;
        }
        if (compareStrings("Yes", requestFileDIClaimIndicator)) {
          directLinkOptionsToShow.fileDisabilityClaim = link.trxDeepLink;
        }
        if (compareStrings("Yes", DIClaimIndicator)) {
          directLinkOptionsToShow.disabilityClaimStatus = link.trxDeepLink;
        }
        break;
      case deepLinkType.PAPERLESS_PREFERENCES:
        if (
          canEditPaperlessPreference &&
          showPaperlessPreference &&
          !paperlessPreference
        ) {
          directLinkOptionsToShow.paperlessPreferences = link.trxDeepLink;
        }
        break;
      default:
        break;
    }
  });

  useDeepCompareEffect(() => {
    Object.keys(directLinkOptionsToShow).forEach((element) => {
      dispatchForm({
        type: types.REPLACE_FIELD,
        formField: {
          ...formData[element],
          directLink: directLinkOptionsToShow[element],
        },
      });
    });
  }, [directLinkOptionsToShow]);

  return { formData, lineOfBusinessCode };
};

export const useCanContinue = (props) => {
  const { pageNumber, nextPage } = props;
  const { agreementKey } = useParams();
  const { trimmedPolicyId } = getTrimmedPolicyIdFromAgreement(agreementKey);
  const {
    user: { email },
  } = useUserState();

  const continueButtonDisplayText =
    pageNumber === 1 ? "Select Direct Links" : "Continue";

  const onContinue = () => {
    fireTealiumEvent({
      label: "SVNT",
      action: `${supportedTransactions.DirectLinks} PageNumber ${pageNumber} ${continueButtonDisplayText} button clicked`,
      category: `${supportedTransactions.DirectLinks}`,
      value: trimmedPolicyId,
      custId: email,
    });
    nextPage();
  };
  return { onContinue, continueButtonDisplayText };
};

export const shouldEnableContinueButton = (formData, pageNumber) => {
  if (pageNumber === 1) {
    return Object.keys(formData).some(
      (key) => formData[key].type === "ownerCheckBox" && formData[key].value
    );
  }
  if (pageNumber === 2) {
    return Object.keys(formData).some(
      (key) => formData[key].type === "linkCheckbox" && formData[key].value
    );
  }
  return true;
};

export const getEligibleOwnerFields = (eligibleOwners) =>
  eligibleOwners.reduce((users, owner, index) => {
    const fieldName = `owner${index}`;
    const fieldNameEmail = `ownerEmail${index}`;

    const { emails, fullName, firstName } = owner;
    const email = getBest(emails)?.value;
    return {
      ...users,
      [fieldName]: {
        id: fieldName,
        label: fullName,
        name: fieldName,
        value: false,
        type: "ownerCheckBox",
        firstName,
      },
      [fieldNameEmail]: {
        id: fieldNameEmail,
        hideLabel: true,
        ariaLabel: `Email input ${fullName}`,
        name: fieldNameEmail,
        value: email,
        errors: [],
        postpone: true,
        useValidation: useEmailValidation,
        disabled: true,
      },
    };
  }, {});

export const getEligibleOwners = (agreementCustomers) => {
  const owners = getUsers(agreementCustomers, "OWNR") || [];
  return owners.filter((owner) => isRegisteredOnMM(owner));
};
export const isEligibleForLoanRequestLink = (agreementData) => {
  const { agreementCustomers, lineOfBusinessCode, requestLoanIndicator } =
    agreementData;

  const owners = getUsers(agreementCustomers, "OWNR") || [];

  const isSingleIndividualOwnedLife =
    owners.length === 1 &&
    owners[0].type === "I" &&
    owners[0].roleSubType === "LIST" &&
    compareArrayOfStrings(["LIFE"], lineOfBusinessCode);

  return (
    isSingleIndividualOwnedLife && compareStrings("Yes", requestLoanIndicator)
  );
};

export const useReviewProps = (formData) => {
  const { selectedOwnerFields, selectedOwnerEmailFields } =
    getSelectedOwnersAndEmails(formData);
  const selectedDirectLinks = getSelectedDirectLinks(formData);

  return { selectedDirectLinks, selectedOwnerFields, selectedOwnerEmailFields };
};

export const getSelectedOwnersAndEmails = (formData) => {
  const { eligibleOwnerCount } = formData;

  const selectedOwnerFields = [];
  const selectedOwnerEmailFields = [];
  for (let i = 0; i < eligibleOwnerCount; i += 1) {
    const currentOwnerIsSelected = formData[`owner${i}`].value;
    if (currentOwnerIsSelected) {
      selectedOwnerFields.push(formData[`owner${i}`]);
      selectedOwnerEmailFields.push(formData[`ownerEmail${i}`]);
    }
  }

  return { selectedOwnerFields, selectedOwnerEmailFields };
};

export const getSelectedDirectLinks = (formData) => {
  const formFieldNames = Object.keys(formData);
  const selectedDirectLinks = formFieldNames
    .filter(
      (fieldName) =>
        compareStrings(formData[fieldName].type, "linkCheckbox") &&
        formData[fieldName].value
    )
    .map((fieldName) => formData[fieldName]);

  return selectedDirectLinks;
};

export const useConfirmationProps = (props) => {
  const { lineOfBusinessCode } = props;
  const { agreementKey } = useParams();
  const { formData } = useFormContext();
  const selectedDirectLinks = getSelectedDirectLinks(formData);
  const { selectedOwnerFields, selectedOwnerEmailFields } =
    getSelectedOwnersAndEmails(formData);
  const { email } = useUserState();
  const { trimmedPolicyId } = getTrimmedPolicyIdFromAgreement(agreementKey);
  const backButtonText = compareStrings(lineOfBusinessCode, "ANN")
    ? "Back to Contract"
    : "Back to Policy";

  const redirectBacktoPolicyWithTealiumEvent = () => {
    fireTealiumAndFollowLink({
      url: `/policyDetails/${encodeURIComponent(agreementKey)}`,
      action: `${lineOfBusinessCode} Direct link confirmation ${backButtonText} button clicked`,
      category: "Direct Links",
      value: trimmedPolicyId,
      custId: email,
    });
  };

  return {
    selectedOwnerFields,
    selectedOwnerEmailFields,
    selectedDirectLinks,
    redirectBacktoPolicyWithTealiumEvent,
    backButtonText,
  };
};
