import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import IsTruthy from "../logic/isTruthy";
import { isValidDPTxnId } from "../../../utils/evaluate";
import { copyClipboardData } from "../../../utils/copyClipboardData";
import { GetErrorReferenceValue } from "./hooks";
import { tealiumPageViewEvent } from "../../../utils/tealium";
import { useUserState } from "../../../contexts/user";
import { DEFAULT_404_ERROR_MESSAGE } from "../../../constants/ui";

const FourHundredFourError = ({ transactionId }) => {
  const { agreementKey } = useParams();

  const errorReferenceValue = transactionId || GetErrorReferenceValue();

  const {
    user: { email },
  } = useUserState();
  useEffect(() => {
    tealiumPageViewEvent({
      page: "404",
      category: agreementKey,
      custId: email,
    });
  }, []);

  return (
    <div className="row">
      <div className="col-12 col-sm-8 offset-sm-2 col-lg-6 offset-lg-3">
        <picture className="justify-content-center mm-spacing">
          <span
            role="img"
            className="illustration-error_404"
            title="Page Not Found"
          />
        </picture>
        <p className="h1 mm-text-center">{DEFAULT_404_ERROR_MESSAGE}</p>
        <div className="justify-content-center">
          <IsTruthy
            value={errorReferenceValue}
            validationFunction={isValidDPTxnId}
          >
            <div className="justify-content-center">
              <span>Error Reference </span>
              <span>&nbsp;</span>
              <span id="transactionId">{errorReferenceValue}</span>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <button
                id="copy_icon_btn"
                className="btn btn-inline"
                type="button"
                onClick={() => copyClipboardData(errorReferenceValue)}
              >
                <span
                  className="icon-copy icon-sm"
                  aria-hidden="true"
                  title="Copy Transaction Id"
                />
                <strong>Copy</strong>
              </button>
            </div>
          </IsTruthy>
        </div>
      </div>
    </div>
  );
};

export default FourHundredFourError;
