import React from "react";
import { useOktaAuth } from "@okta/okta-react";

const LoginCallback = () => {
  const { oktaAuth } = useOktaAuth();
  React.useEffect(() => {
    oktaAuth.handleLoginRedirect().catch(() => {
      window.location.replace(`/loginError`);
    });
  }, [oktaAuth]);
  return null;
};
export default React.memo(LoginCallback);
