import { useEffect, useState } from "react";
import cloneDeep from "lodash.clonedeep";
import AgentServiceTrackingModalCell from "./agentServiceTrackingModalCell";
import { verifyLandingPageNotifications } from "../restrictions";
import { useUserState } from "../../../contexts/user";
import { useLDValue } from "../../common/featureFlags/hooks";
import { searchRows } from "../../../utils/search";
import links from "../../common/table/customCells/links";
import ServiceEventModal from "./serviceEventModal";
import { useMakeRequest } from "../../../hooks/api";
import { useModalState } from "../../../contexts/modal";
import { formatName } from "../../../utils/format";
import { isDefined } from "../../../utils/evaluate";
import { formatDate } from "../../../utils/dates";
import { compareStrings } from "../../../utils/string";

export const useAgentServiceTrackingProps = (props) => {
  const { agentNotificationsRequest, updateNotificationsLastViewedRequest } =
    props;

  const notificationsDataHasLoaded = isDefined(
    agentNotificationsRequest?.data?.notifications
  );

  useEffect(() => {
    updateNotificationsLastViewedRequest.executeRequest();
  }, [notificationsDataHasLoaded]);

  const agentNotificationTableColumns = [
    {
      accessor: "notificationPolicyNumber",
      label: "Policy Number",
      alignment: "left",
      CustomCell: links,
      sortable: "Number",
    },
    {
      accessor: "priority",
      label: "Priority",
      alignment: "left",
      sortable: "String",
    },
    {
      accessor: "eventName",
      label: "Service Event",
      alignment: "left",
      CustomCell: AgentServiceTrackingModalCell,
      sortable: "String",
    },
    {
      accessor: "insuredName",
      label: "Insured Name",
      alignment: "left",
      sortable: "String",
    },
    {
      accessor: "receivedDate",
      label: "Received Date",
      alignment: "left",
      sortable: "Date",
    },
    {
      accessor: "completedDate",
      label: "Completed Date",
      alignment: "left",
      sortable: "Date",
    },
  ];

  const { notifications } = agentNotificationsRequest.data;

  const searchColumns = [
    "notificationPolicyNumber",
    "priority",
    "eventName",
    "insuredName",
    "completedDate",
    "receivedDate",
  ];

  const notificationsSearchTableProps = {
    columns: agentNotificationTableColumns,
    searchColumns,
    notifications,
  };
  const { searchBar, table, noNotificationsReturned } = useFilterTable(
    notificationsSearchTableProps
  );
  const { user } = useUserState();

  const landingPageNotificationsEnabled = useLDValue({
    flagName: ["landingPageNotifications"],
  });

  const { isEligible: agentNotificationEligible } =
    verifyLandingPageNotifications({
      user,
      landingPageNotificationsEnabled,
    });

  const { showModal: showServiceEventModalModal } = useServiceEventModal();

  return {
    searchBar,
    table,
    agentNotificationEligible,
    agentNotificationsRequest,
    showServiceEventModalModal,
    noNotificationsReturned,
  };
};

const useFilterTable = (props) => {
  const {
    searchStringDefault = "",
    showClearIconDefault = false,
    columns = [],
    searchColumns = [],
    notifications = [],
  } = props;

  const [searchString, setSearchString] = useState(searchStringDefault);
  const searchTable = ({ target: { value } }) => setSearchString(value);
  const clearSearch = () => setSearchString("");
  const [showClearIcon, setShowClearIcon] = useState(showClearIconDefault);
  const [notificationRows, setNotificationRows] = useState(
    getRows(notifications)
  );

  let noNotificationsReturned = false;

  if (notifications.length === 0) {
    noNotificationsReturned = true;
  }

  useEffect(() => {
    const searchedRows = searchRows({
      searchString,
      initialRows: cloneDeep(getRows(notifications)),
      columns,
      searchColumns,
    });

    setNotificationRows(searchedRows);
    setShowClearIcon(searchString.length > 0);
  }, [searchString]);

  const priorityOrder = ["High", "Normal"];

  const sortedNotificationRows = notificationRows
    .sort((b, a) => new Date(a.receivedDate) - new Date(b.receivedDate))
    .sort(
      (a, b) =>
        priorityOrder.indexOf(a.priority) - priorityOrder.indexOf(b.priority)
    );

  return {
    searchBar: {
      searchString,
      showClearIcon,
      searchTable,
      clearSearch,
    },
    table: {
      columns,
      sortedNotificationRows,
    },
    noNotificationsReturned,
  };
};

export const useServiceEventModal = () => {
  const { openModal } = useModalState();

  const showModal = (eventData) => () => {
    const { workIdent: workEventId, eventName } = eventData;
    const modalProps = {
      Component: ServiceEventModal,
      title: eventName,
      componentProps: { workEventId, eventName },
      useHook: useServiceEventModalProps,
      modalSize: "lg",
    };
    openModal(modalProps);
  };
  return { showModal };
};

export const useServiceEventModalProps = (props) => {
  const { workEventId } = props;

  const catsByWorkEventIdRequest = useMakeRequest({
    apiRequest: "fetchCatsByWorkEventId",
    apiParams: { workEventId },
    immediateRequest: true,
    dataSelector: "processCatsByWorkEventId",
    devModeDispatch: false,
  });
  const { events } = catsByWorkEventIdRequest?.data || {};

  const [eventData] = events || [];
  const {
    agentId,
    agreementNumber,
    insuredFirstName,
    insuredLastName,
    insuredMiddleName,
    receivedDate,
    completionDate,
    completionInd,
    requestorDesc,
    department,
    requestorName,
    extendedComment,
  } = eventData || {};

  const eventNameObject = {
    firstName: insuredFirstName || "",
    lastName: insuredLastName || "",
    middleName: insuredMiddleName || "",
  };

  const formattedName = formatName({
    nameObject: eventNameObject,
    format: "firstNameDisplayedFirst",
  });

  let formattedStatus = "-";

  if (compareStrings(completionInd, "N")) {
    formattedStatus = "Pending";
  } else if (compareStrings(completionInd, "Y")) {
    formattedStatus = "Resolved-Completed";
  }

  return {
    agentId: isDefined(agentId) ? agentId : "-",
    agreementNumber: isDefined(agreementNumber) ? agreementNumber : "-",
    formattedName: isDefined(formattedName) ? formattedName : "-",
    receivedDate: isDefined(receivedDate) ? formatDate(receivedDate) : "-",
    completionDate: isDefined(completionDate)
      ? formatDate(completionDate)
      : "-",
    catsByWorkEventIdRequest,
    status: isDefined(formattedStatus) ? formattedStatus : "-",
    requestorDesc: isDefined(requestorDesc) ? requestorDesc : "-",
    department: isDefined(department) ? department : "-",
    requestorName: isDefined(requestorName) ? requestorName : "-",
    extendedComment: isDefined(extendedComment) ? extendedComment : "-",
  };
};

export const getRows = (notifications) =>
  notifications?.map((notification) => ({
    notificationPolicyNumber: notification.policyNumber,
    newNotification: notification.isNew,
    priority: compareStrings(notification.isPriority, "Y") ? "High" : "Normal",
    eventName: notification.eventName,
    insuredName: formatName({
      nameObject: {
        fullName: notification.insuredName,
      },
      format: "fullNameThenFirstName",
      defaultReturn: "-",
    }),
    workIdent: notification.workIdent,
    completedDate: formatDate(notification.completionDate) || "-",
    receivedDate: formatDate(notification.receivedDate) || "-",
    agreementKey: notification.agreementKey,
  }));
