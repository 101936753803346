import React from "react";
import { useSiteDownProps } from "../../../hooks/helper";

const SiteDown = () => {
  const { messageToDisplay } = useSiteDownProps();

  return (
    <>
      <div className="mm-text-center mm-spacing--minor">
        <picture>
          <span
            role="img"
            className="illustration-error_site_down"
            title="Error Site Down"
          />
        </picture>
      </div>
      <h1 className="justify-content-center">Our site is temporarily down.</h1>
      <p
        className="h4 justify-content-center"
        dangerouslySetInnerHTML={{ __html: messageToDisplay }}
      />
    </>
  );
};

export default SiteDown;
