import React from "react";
import Table from "../../common/table";
import TableControl from "../../common/table/tableControl";
import IsFalsy from "../../common/logic/isFalsy";
import IsTruthy from "../../common/logic/isTruthy";
import LdBannerContent from "../../common/molecules/ldBannerContent";
import FourHundredFourError from "../../common/errors/404";
import { useAgentServiceTrackingProps } from "./hooks";

const AgentServiceTrackingDetails = (props) => {
  const { numberOfDaysThreshold } = props;
  const {
    table: { columns, sortedNotificationRows },
    searchBar: { searchString, showClearIcon, searchTable, clearSearch },
    agentNotificationEligible,
    showServiceEventModalModal,
    noNotificationsReturned,
  } = useAgentServiceTrackingProps(props);

  const noResultsText = noNotificationsReturned
    ? "You have no notifications at this time."
    : "There are no matching results.";

  return (
    <div id="agentServiceTracking">
      <IsFalsy value={agentNotificationEligible}>
        <div className="container">
          <LdBannerContent />
          <FourHundredFourError />
        </div>
      </IsFalsy>
      <IsTruthy value={agentNotificationEligible}>
        <div className="row">
          <div className="container">
            <h1 className="h2" id="service_tracking_agent_summary_header">
              Service Tracking - Agent Summary
            </h1>
            <TableControl
              searchString={searchString}
              searchTable={searchTable}
              showClearIcon={showClearIcon}
              clearSearch={clearSearch}
              placeHolderText="Filter Results"
            />
            <Table
              columns={columns}
              rows={sortedNotificationRows}
              defaultSortColumn="receivedDate"
              customFunctions={{ showServiceEventModalModal }}
              noResultsText={noResultsText}
              id="landingpagenotifications-agentservicetracking"
            />
            <IsFalsy value={sortedNotificationRows.length === 0}>
              <p>
                {`These are your notifications for the last ${numberOfDaysThreshold} days.`}
              </p>
            </IsFalsy>
          </div>
        </div>
      </IsTruthy>
    </div>
  );
};

export default AgentServiceTrackingDetails;
