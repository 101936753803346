import React, { useEffect } from "react";
import { useLDValue } from "../common/featureFlags/hooks";
import { fireTealiumAndFollowLink } from "../../utils/tealium";
import IsTruthy from "../common/logic/isTruthy";
import IsFalsy from "../common/logic/isFalsy";
import { verifyFillableFormLibraryLink } from "../sendFillableForm/restrictions";

const FillableFormLibraryLink = (props) => {
  const {
    agreementData,
    setShowFillableFormLibraryLink = () => {},
    policyId,
    userEmail,
    tealiumCategory,
    target,
    isRenderAsListItem = false,
    cssClassName = "btn btn-inline",
  } = props;

  const { agreementKey } = agreementData;

  const transactionSupportedPolicies = useLDValue({
    flagName: ["transactionSupportedPolicies"],
  });

  const { isEligible, isTransactionAllowed } = verifyFillableFormLibraryLink({
    agreementData,
    transactionSupportedPolicies,
  });

  const isEligibleForFillableFormLibraryLink =
    isEligible && isTransactionAllowed;

  useEffect(() => {
    setShowFillableFormLibraryLink(isEligibleForFillableFormLibraryLink);
  }, [isEligibleForFillableFormLibraryLink]);

  return (
    <IsTruthy value={isEligibleForFillableFormLibraryLink}>
      <IsTruthy value={isRenderAsListItem}>
        <li>
          <button
            type="button"
            id="fillableForms-links-link"
            className={cssClassName}
            onClick={() =>
              fireTealiumAndFollowLink({
                url: `/policyDetails/${encodeURIComponent(
                  agreementKey
                )}/sendFillableForm`,
                action: "Fillable Form Links link clicked",
                category: tealiumCategory,
                value: policyId,
                custId: userEmail,
                target,
              })
            }
          >
            Docusign Forms
          </button>
        </li>
      </IsTruthy>
      <IsFalsy value={isRenderAsListItem}>
        <button
          className={cssClassName}
          id="fillableForms-links-link"
          type="button"
          onClick={() =>
            fireTealiumAndFollowLink({
              url: `/policyDetails/${encodeURIComponent(
                agreementKey
              )}/sendFillableForm`,
              action: "Fillable Form Links link clicked",
              category: tealiumCategory,
              value: policyId,
              custId: userEmail,
            })
          }
        >
          Docusign Forms
        </button>
      </IsFalsy>
    </IsTruthy>
  );
};

export default React.memo(FillableFormLibraryLink);
