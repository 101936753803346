import add from "date-fns/add";
import addYears from "date-fns/addYears";
import isLeapYear from "date-fns/isLeapYear";
import isAfter from "date-fns/isAfter";
import moment from "moment";
import isBefore from "date-fns/isBefore";
import {
  addDays,
  differenceInDays,
  intervalToDuration,
  isValid,
  parse,
  subYears,
} from "date-fns";
import { isString, compareArrayOfStrings } from "./string";
import { isANumber, isDate } from "./evaluate";

const getCurrentDate = () =>
  formatDate(
    moment().utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
    "monthCommaDayYear"
  );

const isQuarterString = (date) => {
  if (isString(date)) {
    const month = date.split(" ")[0];
    const quarterEndMonths = ["March", "June", "Sept.", "Dec."];
    return compareArrayOfStrings(quarterEndMonths, month);
  }
  return false;
};

export const splitDate = (date) => {
  if (!isString(date)) {
    return date;
  }

  const dateObject = moment(date);
  if (dateObject.isValid()) {
    const [YYYY, MM, DD] = dateObject.format("YYYY-MM-DD").split("-");
    return [YYYY, MM, DD, dateObject];
  }
  return date;
};

// we only support two formats Month Day, Year and mm/dd/yyyy
const formatDate = (date, format, defaultReturn = "") => {
  const dateToMoment = moment(date);
  if (!date || !dateToMoment._isValid) {
    return defaultReturn;
  }

  // eslint-disable-next-line no-unused-vars
  const [year, month, day] = splitDate(date);
  if (format === "monthCommaDayYear") {
    const monthSpelledOut = moment(month, "MM").format("MMMM");
    const numberDay = parseInt(day, 10);

    return `${abbreviateMonth(monthSpelledOut)} ${numberDay}, ${year}`;
  }
  if (format === "dateWithTime") {
    return moment().format(`MM/DD/YYYY, h:mm A`);
  }
  if (format === "YYYYMMDD") {
    return moment(date).format(`YYYY/MM/DD`);
  }
  if (format === "YYYY-MM-DD") {
    return moment(date).format(`YYYY-MM-DD`);
  }
  return moment.utc(date.toLocaleString()).format("MM/DD/YYYY");
};

// we need this becuase September has a 4 letter abbreviation instead of 3
const abbreviateMonth = (month) => {
  switch (month) {
    case "January":
    case "February":
    case "August":
    case "October":
    case "November":
    case "December":
      return `${month.substring(0, 3)}.`;
    case "September":
      return `${month.substring(0, 4)}.`;
    case "March":
    case "April":
    case "May":
    case "June":
    case "July":
      return `${month}`;
    default:
      return "";
  }
};

const getAge = (birthDate, defaultReturn = "") => {
  const today = new Date();
  const birthDay = new Date(birthDate);
  let years = today.getFullYear() - birthDay.getFullYear();
  const m = today.getMonth() - birthDay.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDay.getDate())) {
    // eslint-disable-next-line no-plusplus
    years--;
  }
  return isANumber(years) ? years : defaultReturn;
};

const getFullAge = (birthDate, defaultReturn = "") => {
  const formattedBirthDate = parse(birthDate, "MM/dd/yyyy", new Date());
  if (!isValid(formattedBirthDate)) {
    return defaultReturn;
  }
  const { years, months, days } = intervalToDuration({
    start: formattedBirthDate,
    end: new Date(),
  });
  return { years, months, days };
};

const getDaysDifference = (laterDate, earlierDate) => {
  const differenceInDaysResult = differenceInDays(laterDate, earlierDate);
  return differenceInDaysResult;
};

const getDateDifference = (startDate, endDate, defaultReturn = "") => {
  const formattedStartDate = parse(
    formatDate(startDate, "MM/dd/yyyy"),
    "MM/dd/yyyy",
    new Date()
  );
  const formattedEndDate = parse(
    formatDate(endDate, "MM/dd/yyyy"),
    "MM/dd/yyyy",
    new Date()
  );

  if (!isValid(formattedStartDate) || !isValid(formattedEndDate)) {
    return defaultReturn;
  }
  const { years, months, days } = intervalToDuration({
    start: formattedStartDate,
    end: formattedEndDate,
  });
  return { years, months, days };
};

const isSameDate = (dateLeft, dateRight) => {
  const { years, months, days } = getDateDifference(dateLeft, dateRight);
  return years === 0 && months === 0 && days === 0;
};

// https://date-fns.org/v2.16.1/docs/add
const addTimeToDate = (props) => {
  const { date, duration } = props;
  if (!date) {
    return "";
  }
  const startingDate = new Date(date);

  return add(startingDate, duration);
};

const addYeartoDate = (date, numberofyears) => {
  const dateObject = new Date(date);
  return addYears(dateObject, numberofyears);
};

const addDaystoDate = (date, numberofDays) => {
  const dateObject = new Date(date);
  return addDays(dateObject, numberofDays);
};

const subtractYearstoDate = (date, numberofYears) => {
  const dateObjet = new Date(date);
  return subYears(dateObjet, numberofYears);
};

const isAfterDate = (date, dateBeingComparedTo) => {
  const currentDate = new Date(date);
  const dateToCompareTo = new Date(dateBeingComparedTo);

  return isAfter(currentDate, dateToCompareTo);
};

const isBeforeDate = (date, dateBeingComparedTo) => {
  const currentDate = new Date(date);
  const dateToCompareTo = new Date(dateBeingComparedTo);

  return isBefore(currentDate, dateToCompareTo);
};

const isleapYear = (date) => {
  const dateValue = new Date(date);
  return isLeapYear(dateValue);
};

const createDateObject = (dateString) => {
  const dateObject = new Date(dateString);
  if (isDate(dateObject)) {
    return dateObject;
  }

  return null;
};

const getMonthFromDate = (dateString) => moment(dateString).month();

const getDayFromDate = (dateString) => moment(dateString).date();

const getCurrentDateTimeWithTimezone = () => {
  const currentTime = moment().format("M/D/YYYY HH:mm:ss a");
  const zoneName = moment.tz.guess();
  const timezone = moment.tz(zoneName).zoneAbbr();
  const currentTimeWithTimezone = `${currentTime} ${timezone}`;
  return currentTimeWithTimezone;
};

export {
  getCurrentDate,
  formatDate,
  getAge,
  getFullAge,
  addTimeToDate,
  isAfterDate,
  isBeforeDate,
  isQuarterString,
  abbreviateMonth,
  createDateObject,
  addYeartoDate,
  isleapYear,
  addDaystoDate,
  getMonthFromDate,
  getDayFromDate,
  getCurrentDateTimeWithTimezone,
  subtractYearstoDate,
  getDateDifference,
  isSameDate,
  getDaysDifference,
};
